/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";

import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
    makeStyles,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FileInputComponent from "./../../../../components/file-input/file-input.view";
import * as configCommon from "./../../../../common/config";

import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import * as viVN from "../../../../language/vi-VN.json";
import * as appActions from "../../../../core/app.store";
import * as planningAction from "../../../../redux/store/planning/planning.store";
import * as provinceAction from "../../../../redux/store/province-management/province.store";
import * as districtAction from "../../../../redux/store/district-management/district.store";
import * as communeAction from "../../../../redux/store/commune-management/commune.store";
import * as config from "../../../../utils/configuration";

import FileManagement from "../../../../components/file_management/file_management";

function EditRecordsManagement(props) {
  const classes = useStyles();

  const { isOpen, planningId, onClose, onSuccess, showLoading, cgisId } = props;

  const [planningModel, setPlanningModel] = useState(null);
  const [planningLookUpModel, setPlanningLookUpModel] = useState(null);
  const [planningStatusModel, setPlanningStatusModel] = useState([]);
  const [planningLevelModel, setPlanningLevelModel] = useState([]);
  const [planningTypeModel, setPlanningTypeModel] = useState([]);
  const [documentTypeModel, setDocumentTypeModel] = useState([]);
  const [approvalAgencyModel, setApprovalAgencyModel] = useState([]);
  const [lookupProvinceModel, setLookupProvinceModel] = useState([]);
    const [lookupDistrictModel, setLookupDistrictModel] = useState([]);
    const [lookupCommuneModel, setLookupCommuneModel] = useState([]);

  const [planningStatus, setPlanningStatus] = useState("");
  const [planningLevel, setPlanningLevel] = useState("");
  const [planningType, setPlanningType] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [approvalAgency, setApprovalAgency] = useState("");
  const [lookupProvince, setLookupProvince] = useState(null);
    const [lookupDistrict, setLookupDistrict] = useState(null);
    const [lookupCommune, setLookupCommune] = useState(null);

  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);
  const [isShow, setShow] = useState(false);
  const [tifFiles, setTifFiles] = useState([]);
  const [tifFilesTemp, setTifFilesTemp] = useState([]);
  const [isShowTif, setShowTif] = useState(false);
  const [zipFiles, setZipFiles] = useState([]);
  const [zipFilesTemp, setZipFilesTemp] = useState([]);
  const [isShowZip, setShowZip] = useState(false);
  const [tifName, setTifName] = useState(null);
  const [zipName, setZipName] = useState(null);
    const [isActive, setIsActive] = useState(true);
    const [isShowHomepage, setIsShowHomepage] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    clearErrors,
    setError,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    onGetData();
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      onGetDetailPlanning(),
      getLookupProvince(),
      onGetLookUpPlanning(),
      onGetLookUpPlanningStatus(),
      onGetLookUpPlanningLevel(),
      onGetLookUpPlanningType(),
      onGetLookUpDocumentType(),
      getApprovalAgencyLevel(),
    ])
      .then((res) => {
        const [planning, lookupProvince] = res;
          setProvince(lookupProvince.content, planning.content);
          setDistrict(planning.content);
          setCommune(planning.content);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const setProvince = (lookupProvince, planning) => {
    if (!lookupProvince || !planning) return;

    let province =
      planning.provinceNames &&
      planning.provinceNames.length > 0 &&
      lookupProvince.filter(
        (item) =>
          planning.provinceNames.some((name) => name === item.name) === true
      );
      lookupProvince.length > 0 && setLookupProvince(province);

      if (province.length > 0) {
      setValue("lookupProvinceId", "lookupProvinceId");
      clearErrors("lookupProvinceId");
    } else {
      setValue("lookupProvinceId", "");
      setError("lookupProvinceId", { type: "required" });
    }
    };

    const setDistrict = (planning) => {
        if (!planning) return;

        districtAction.GetLookupDistrict(planning.provinceIds).then(
            (res) => {
                if (res && res.content) {
                    console.log(res.content);
                    setLookupDistrictModel(res.content);
                    let district =
                        planning.districtIds &&
                        planning.districtIds.length > 0 &&
                        res.content.filter(
                            (item) =>
                                planning.districtIds.some((id) => id === item.id) === true
                        );

                    res.content.length > 0 && setLookupDistrict(district);
                    if (district.length > 0) {
                        setValue("lookupDistrictId", "lookupDistrictId");
                        clearErrors("lookupDistrictId");
                    } else {
                        setValue("lookupDistrictId", "");
                        setError("lookupDistrictId", { type: "required" });
                    }
                }
            },
            (err) => {
                //reject(err);
                err &&
                    err.errorType &&
                    ShowNotification(
                        viVN.Errors[err.errorType],
                        NotificationMessageType.Error
                    );
            }
        );
    };

    const setCommune = (planning) => {
        if (!planning) return;

        communeAction.GetLookupCommuneMultiDistrict(planning.districtIds).then(
            (res) => {
                if (res && res.content) {
                    setLookupCommuneModel(res.content);

                    let commune =
                        planning.communeIds &&
                        planning.communeIds .length > 0 &&
                        res.content.filter(
                            (item) =>
                                planning.communeIds.some((id) => id === item.id) === true
                        );

                    res.content.length > 0 && setLookupCommune(commune);
                }
            },
            (err) => {
                //reject(err);
                err &&
                    err.errorType &&
                    ShowNotification(
                        viVN.Errors[err.errorType],
                        NotificationMessageType.Error
                    );
            }
        );
    };

  const onGetDetailPlanning = (id = planningId) => {
    return new Promise((resolve, reject) => {
      planningAction.GetDetailPlaning(id).then(
        (res) => {
          if (!res || !res.content) {
            reject(res);
          }

          setPlanningModel(res.content);
          setFiles(res.content.files ? [res.content.files] : []);
              setLookupProvince(res.content.provinceIds || []);
              setIsActive(res.content.isActive);
              setIsShowHomepage(res.content.isShowHomePage);
          resolve(res);
        },
        (err) => {
          reject(err);
          ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
          );
        }
      );
    });
  };

  const onGetLookUpPlanning = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpPlanning().then(
        (res) => {
          setPlanningLookUpModel(res && res.content);
          resolve(res);
        },
        (err) => {
          reject(err);
          ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
          );
        }
      );
    });
  };

  const onGetLookUpPlanningStatus = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpPlanningStatus().then(
        (res) => {
          setPlanningStatusModel(res && res.content);
          resolve(res);
        },
        (err) => {
          reject(err);
          ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
          );
        }
      );
    });
  };

  const onGetLookUpPlanningLevel = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpPlanningLevel().then(
        (res) => {
          setPlanningLevelModel(res && res.content);
          resolve(res);
        },
        (err) => {
          reject(err);
          ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
          );
        }
      );
    });
  };

  const onGetLookUpPlanningType = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpPlanningType().then(
        (res) => {
          setPlanningTypeModel(res && res.content);
          resolve(res);
        },
        (err) => {
          reject(err);
          ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
          );
        }
      );
    });
  };

  const getApprovalAgencyLevel = () => {
    return new Promise((resolve, reject) => {
      planningAction.ApprovalAgencyLevel().then(
        (res) => {
          setApprovalAgencyModel(res && res.content);
          resolve(res);
        },
        (err) => {
          reject(err);
          ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
          );
        }
      );
    });
  };

  const getLookupProvince = () => {
    return new Promise((resolve, reject) => {
      provinceAction.GetLookupProvince().then(
        (res) => {
          setLookupProvinceModel(res && res.content);
          resolve(res);
        },
        (err) => {
          reject(err);
          ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
          );
        }
      );
    });
    };

    const getLookupDistrict = (listProvinceIds) => {
        return new Promise((resolve, reject) => {
            districtAction.GetLookupDistrict(listProvinceIds).then(
                (res) => {
                    if (res && res.content)
                        setLookupDistrictModel(res.content);
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

    const getLookupCommune = (listDistrictIds) => {
        return new Promise((resolve, reject) => {
            communeAction.GetLookupCommuneMultiDistrict(listDistrictIds).then(
                (res) => {
                    if (res && res.content)
                        setLookupCommuneModel(res.content);
                    resolve(res);
                },
                (err) => {
                    reject(err);
                    err &&
                        err.errorType &&
                        ShowNotification(
                            viVN.Errors[err.errorType],
                            NotificationMessageType.Error
                        );
                }
            );
        });
    };

  const onGetLookUpDocumentType = () => {
    return new Promise((resolve, reject) => {
      planningAction.GetLookUpDocumentType().then(
        (res) => {
          setDocumentTypeModel(res && res.content);
          resolve(res);
        },
        (err) => {
          reject(err);
          ShowNotification(
            viVN.Errors[(err && err.errorType) || "UnableHandleException"],
            NotificationMessageType.Error
          );
        }
      );
    });
  };

  const onSubmit = (data) => {
    if (!data) {
      return;
    }

    showLoading(true);

    let params = {
      Id: planningModel.id || null,
      Name: data.planningName || null,
      PlanningCode: planningModel.planningCode || null,
      Photo: planningModel.photo || null,
      PlanningTypeId:
        (planningType && planningType.id) ||
        planningModel.planningTypeId ||
        null,
      Place: data.place || null,
      DistrictId: planningModel.districtId || null,
      Order: parseInt(data.order) || null,
      PlanningAgency: data.planningAgency || null,
      ConsultingUnit: data.consultingUnit || null,
      Investor: data.investor || null,
      PlanningStatusId:
        (planningStatus && planningStatus.id) ||
        planningModel.planningStatusId ||
        null,
      AgencySubmitted: data.agencySubmitted || null,
      PlanningUnit: data.planningUnit || null,
      PlanningLevelId:
        (planningLevel && planningLevel.id) ||
        planningModel.planningLevelId ||
        null,
      Population: parseInt(data.population) || null,
      Acreage: data.acreage || null,
      LandForConstruction: data.landForConstruction || null,
      Report: data.report || null,
        Note: data.note || null,
        isActive: isActive,
        isShowHomepage: isShowHomepage,
      DocumentTypeId:
        (documentType && documentType.id) ||
        planningModel.documentTypeId ||
        null,
      ApprovalAgencyLevelId:
        (approvalAgency && approvalAgency.id) ||
        planningModel.approvalAgencyLevelId ||
        null,
      PlanningProvinceIds:
        (lookupProvince &&
          lookupProvince.map((item) => {
            return item.id;
          })) ||
            null,
        PlanningDistrictIds:
            (lookupDistrict &&
                lookupDistrict.length > 0 &&
                lookupDistrict.map((item) => {
                    return item.id;
                })) ||
            null,
        PlanningCommuneIds:
            (lookupCommune &&
                lookupCommune.length > 0 &&
                lookupCommune.map((item) => {
                    return item.id;
                })) ||
            null,
    };

    if (files && files.length > 0) {
      params = {
        ...params,
        DocumentUploadId: files && files[0].fileId,
      };
    }

    if (tifFiles && tifFiles.length > 0) {
      params = {
        ...params,
        tifFile: tifFiles && tifFiles[0].fileId,
      };
    }

    if (zipFiles && zipFiles.length > 0) {
      params = {
        ...params,
        shpFile: zipFiles && zipFiles[0].fileId,
      };
    }

    planningAction.UpdatePlanning(params).then(
      (res) => {
        onSuccess();
      },
      (err) => {
        showLoading(false);
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    ).catch((err) => {
      showLoading(false);
      ShowNotification(
        viVN.Errors[(err && err.errorType) || "UnableHandleException"],
        NotificationMessageType.Error
      );
    });;
  };

  const onOpenSelectTifFile = () => {
    setShowTif(true);
    setTifFilesTemp(tifFiles);
  }

  const onCloseSelectTifFile = () => {
    setShowTif(false);
    setTifFiles(tifFilesTemp);
  }

  const onSaveSelectTifFile = () => {
    setShowTif(false);
    setTifName(tifFiles[0].fileName);
  };

  const onOpenSelectZipFile = () => {
    setShowZip(true);
    setZipFilesTemp(zipFiles);
  }

  const onCloseSelectZipFile = () => {
    setShowZip(false);
    setZipFiles(zipFilesTemp);
  }

  const onSaveSelectZipFile = () => {
    setShowZip(false);
    setZipName(zipFiles[0].fileName);
  };

  const onOpenSelectFile = () => {
    setShow(true);
    setFilesTemp(files);
  };

  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
  };

  const onSaveSelectFile = () => {
    setShow(false);
  };

    const handleChangeStatus = (event) => {
        event.persist();
        setIsActive(event.target.checked);
    }

    const handleChangeShowHomepage = (event) => {
        event.persist();
        setIsShowHomepage(event.target.checked);
    }

    const onChangeProvince = (newValue) => {
        setLookupProvince(newValue);
        let listPrvIds = newValue &&
            newValue.length > 0 &&
            newValue.map((item) => {
                return item.id;
            });
        getLookupDistrict(listPrvIds);
        if (newValue.length > 0) {
            setValue("lookupProvinceId", "11");
            clearErrors("lookupProvinceId");
        } else {
            setValue("lookupProvinceId", "");
            setError("lookupProvinceId", { type: "required" });
        }
    }

    const onChangeDistrict = (newValue) => {
        setLookupDistrict(newValue);
        let listDstIds = newValue &&
            newValue.length > 0 &&
            newValue.map((item) => {
                return item.id;
            });
        getLookupCommune(listDstIds);
        if (newValue.length > 0) {
            setValue("lookupDistrictId", "11");
            clearErrors("lookupDistrictId");
        } else {
            setValue("lookupDistrictId", "");
            setError("lookupDistrictId", { type: "required" });
        }
    }

  return (
    <div>
      {isOpen && (
        <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
          <DialogTitle
            disableTypography
            id="alert-dialog-title"
            className="border-bottom"
          >
            <Typography variant="h6">Chỉnh sửa dự án quy hoạch</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <form onSubmit={handleSubmit(onSubmit)}>
            {planningModel && (
              <DialogContent className="pt-4 pb-2">
                <div className="form-group row">
                  <div className="col-6">
                    <label className="text-dark">
                      Mã quy hoạch<span className="required"></span>
                    </label>

                    <TextField
                      fullWidth
                      type="text"
                      name="planningCode"
                      defaultValue={planningModel.planningCode}
                      disabled
                      variant="outlined"
                      size="small"
                    />
                  </div>
                  <div className="col-6">
                    <label className="text-dark">
                      Tên đồ án QH<span className="required"></span>
                    </label>

                    <TextField
                      fullWidth
                      inputRef={register({ required: true })}
                      type="text"
                      name="planningName"
                      defaultValue={planningModel.name}
                      error={
                        errors.planningName &&
                        errors.planningName.type === "required"
                      }
                      variant="outlined"
                      size="small"
                    />
                    {errors.planningName &&
                      errors.planningName.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-6">
                    <label className="text-dark">
                      Loại quy hoạch<span className="required"></span>
                    </label>

                    {planningTypeModel && planningTypeModel.length > 0 && (
                      <Autocomplete
                        options={planningTypeModel}
                        getOptionLabel={(option) => option.name}
                        value={
                          planningType ||
                            planningTypeModel.some(
                              (item) => item.id === planningModel.planningTypeId
                            )
                            ? planningTypeModel.find(
                              (item) =>
                                item.id === planningModel.planningTypeId
                            )
                            : null
                        }
                        onChange={(event, newValue) => {
                          setPlanningType(newValue);
                        }}
                        disableClearable={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="planningTypeId"
                            inputRef={register({ required: true })}
                            error={
                              errors.planningTypeId &&
                              errors.planningTypeId.type === "required"
                            }
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    )}
                    {errors.planningTypeId &&
                      errors.planningTypeId.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                  </div>

                  <div className="col-6">
                    <label className="text-dark">
                      Địa điểm<span className="required"></span>
                    </label>

                    <TextField
                      fullWidth
                      inputRef={register({ required: true })}
                      type="text"
                      name="place"
                      defaultValue={planningModel.place}
                      error={errors.place && errors.place.type === "required"}
                      variant="outlined"
                      size="small"
                    />
                    {errors.place && errors.place.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-6">
                    <label className="text-dark">
                      Thứ tự<span className="required"></span>
                    </label>

                    <TextField
                      fullWidth
                      inputRef={register({ required: true })}
                      type="text"
                      name="order"
                      onChange={(e) =>
                        setValue("order", e.target.value.replace(/[^0-9]/g, ""))
                      }
                      defaultValue={planningModel.order}
                      error={errors.order && errors.order.type === "required"}
                      variant="outlined"
                      size="small"
                    />
                    {errors.order && errors.order.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  </div>

                  <div className="col-6">
                    <label className="text-dark">
                      Cấp quy hoạch<span className="required"></span>
                    </label>

                    {planningLevelModel && planningLevelModel.length > 0 && (
                      <Autocomplete
                        id="planningLevelId"
                        options={planningLevelModel}
                        getOptionLabel={(option) => option.name}
                        value={
                          planningLevel ||
                            planningLevelModel.some(
                              (item) => item.id === planningModel.planningLevelId
                            )
                            ? planningLevelModel.find(
                              (item) =>
                                item.id === planningModel.planningLevelId
                            )
                            : null
                        }
                        onChange={(event, newValue) => {
                          setPlanningLevel(newValue);
                        }}
                        disableClearable={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="planningLevelId"
                            inputRef={register({ required: true })}
                            error={
                              errors.planningLevelId &&
                              errors.planningLevelId.type === "required"
                            }
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    )}
                    {errors.planningLevelId &&
                      errors.planningLevelId.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-6">
                    <label className="text-dark">
                      Cơ quan phê duyệt
                      <span className="required"></span>
                    </label>

                    <TextField
                      fullWidth
                      inputRef={register({ required: true })}
                      type="text"
                      name="planningAgency"
                      defaultValue={planningModel.planningAgency}
                      error={
                        errors.planningAgency &&
                        errors.planningAgency.type === "required"
                      }
                      variant="outlined"
                      size="small"
                    />
                    {errors.planningAgency &&
                      errors.planningAgency.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                  </div>

                  <div className="col-6">
                    <label className="text-dark">
                      Đơn vị lập quy hoạch<span className="required"></span>
                    </label>

                    <TextField
                      fullWidth
                      inputRef={register({ required: true })}
                      type="text"
                      name="planningUnit"
                      defaultValue={planningModel.planningUnit}
                      error={
                        errors.planningUnit &&
                        errors.planningUnit.type === "required"
                      }
                      variant="outlined"
                      size="small"
                    />
                    {errors.planningUnit &&
                      errors.planningUnit.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-6">
                    <label className="text-dark">
                      Cơ quan trình
                      <span className="required"></span>
                    </label>

                    <TextField
                      fullWidth
                      inputRef={register({ required: true })}
                      type="text"
                      name="agencySubmitted"
                      defaultValue={planningModel.agencySubmitted}
                      error={
                        errors.agencySubmitted &&
                        errors.agencySubmitted.type === "required"
                      }
                      variant="outlined"
                      size="small"
                    />
                    {errors.agencySubmitted &&
                      errors.agencySubmitted.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                  </div>

                  <div className="col-6">
                    <label className="text-dark">
                      Đơn vị tư vấn<span className="required"></span>
                    </label>

                    <TextField
                      fullWidth
                      inputRef={register({ required: true })}
                      type="text"
                      name="consultingUnit"
                      defaultValue={planningModel.consultingUnit}
                      error={
                        errors.consultingUnit &&
                        errors.consultingUnit.type === "required"
                      }
                      variant="outlined"
                      size="small"
                    />
                    {errors.consultingUnit &&
                      errors.consultingUnit.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-6">
                    <label className="text-dark">
                      Chủ đầu tư<span className="required"></span>
                    </label>

                    <TextField
                      fullWidth
                      inputRef={register({ required: true })}
                      type="text"
                      name="investor"
                      defaultValue={planningModel.investor}
                      error={
                        errors.investor && errors.investor.type === "required"
                      }
                      variant="outlined"
                      size="small"
                    />
                    {errors.investor && errors.investor.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  </div>
                  <div className="col-6">
                    <label className="text-dark">Quyết định phê duyệt</label>
                    <TextField
                      fullWidth
                      type="text"
                      name="report"
                      defaultValue={planningModel.report}
                      variant="outlined"
                      size="small"
                      inputRef={register}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-6">
                    <label className="text-dark">
                      Loại hồ sơ<span className="required"></span>
                    </label>

                    {documentTypeModel && documentTypeModel.length > 0 && (
                      <Autocomplete
                        id="documentTypeId"
                        options={documentTypeModel}
                        getOptionLabel={(option) => option.name}
                        value={
                          documentType ||
                            documentTypeModel.some(
                              (item) => item.id === planningModel.documentTypeId
                            )
                            ? documentTypeModel.find(
                              (item) =>
                                item.id === planningModel.documentTypeId
                            )
                            : null
                        }
                        onChange={(event, newValue) => {
                          setDocumentType(newValue);
                        }}
                        disableClearable={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="documentTypeId"
                            inputRef={register({ required: true })}
                            error={
                              errors.documentTypeId &&
                              errors.documentTypeId.type === "required"
                            }
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    )}
                    {errors.documentTypeId &&
                      errors.documentTypeId.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                  </div>

                  <div className="col-6">
                    <label className="text-dark">
                      Dân số (người)<span className="required"></span>
                    </label>

                    <TextField
                      fullWidth
                      inputRef={register({ required: true })}
                      type="text"
                      name="population"
                      defaultValue={planningModel.population}
                      onChange={(e) =>
                        setValue(
                          "population",
                          e.target.value.replace(/[^0-9]/g, "")
                        )
                      }
                      error={
                        errors.population &&
                        errors.population.type === "required"
                      }
                      variant="outlined"
                      size="small"
                    />
                    {errors.population &&
                      errors.population.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-6">
                    <label className="text-dark">
                      Diện tích (ha)<span className="required"></span>
                    </label>

                    <TextField
                      fullWidth
                      inputRef={register({
                        required: true,
                        pattern: /^\d+(\.\d{1,9}?|,\d{1,9})?$/,
                      })}
                      type="text"
                      name="acreage"
                      defaultValue={planningModel.acreage}
                      error={
                        errors.acreage &&
                        (errors.acreage.type === "required" ||
                          errors.acreage.type === "pattern")
                      }
                      variant="outlined"
                      size="small"
                    />
                    {errors.acreage && errors.acreage.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                    {errors.acreage && errors.acreage.type === "pattern" && (
                      <span className="error">
                        Chỉ có thể nhập số hoặc số thập phân
                      </span>
                    )}
                  </div>

                  <div className="col-6">
                    <label className="text-dark">
                      Đất xây dựng (ha)<span className="required"></span>
                    </label>

                    <TextField
                      fullWidth
                      inputRef={register({
                        required: true,
                        pattern: /^\d+(\.\d{1,9}?|,\d{1,9})?$/,
                      })}
                      type="text"
                      name="landForConstruction"
                      defaultValue={planningModel.landForConstruction}
                      error={
                        errors.landForConstruction &&
                        (errors.landForConstruction.type === "required" ||
                          errors.landForConstruction.type === "pattern")
                      }
                      variant="outlined"
                      size="small"
                    />
                    {errors.landForConstruction &&
                      errors.landForConstruction.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                    {errors.landForConstruction &&
                      errors.landForConstruction.type === "pattern" && (
                        <span className="error">
                          Chỉ có thể nhập số hoặc số thập phân
                        </span>
                      )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-6">
                    <label className="text-dark">
                      Tỉnh/Thành phố<span className="required"></span>
                    </label>
                    {lookupProvinceModel && lookupProvinceModel.length > 0 && (
                      <Autocomplete
                        multiple
                        options={lookupProvinceModel}
                        getOptionLabel={(option) => option.name}
                        defaultValue={lookupProvinceModel.filter((item) => {
                          if (
                            planningModel &&
                            planningModel.provinceNames &&
                            planningModel.provinceNames.length > 0 &&
                            planningModel.provinceNames.some(
                              (name) => name === item.name
                            )
                          ) {
                            return item;
                          }
                        })}
                        onChange={(event, newValue) => {
                            onChangeProvince(newValue)
                        }
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={
                              errors.lookupProvinceId &&
                              errors.lookupProvinceId.type === "required"
                            }
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    )}
                    <TextField
                      name="lookupProvinceId"
                      hidden
                      inputRef={register({ required: true })}
                    />
                    {errors.lookupProvinceId &&
                      errors.lookupProvinceId.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                  </div>
                                  <div className="col-6">
                                      <label className="text-dark">
                                          Quận/huyện<span className="required"></span>
                                      </label>
                                      {lookupDistrictModel && lookupDistrictModel.length > 0 && (
                                          <Autocomplete
                                              multiple
                                              options={lookupDistrictModel}
                                              getOptionLabel={(option) => option.name}
                                              defaultValue={lookupDistrictModel.filter((item) => {
                                                  if (
                                                      planningModel &&
                                                      planningModel.districtIds &&
                                                      planningModel.districtIds.length > 0 &&
                                                      planningModel.districtIds.some(
                                                          (id) => id === item.id
                                                      )
                                                  ) {
                                                      return item;
                                                  }
                                              })}
                                              onChange={(event, newValue) => {
                                                  onChangeDistrict(newValue);
                                              }}
                                              renderInput={(params) => (
                                                  <TextField
                                                      {...params}
                                                      error={
                                                          errors.lookupDistrictId &&
                                                          errors.lookupDistrictId.type === "required"
                                                      }
                                                      variant="outlined"
                                                      size="small"
                                                  />
                                              )}
                                          />
                                      )}
                                      <TextField
                                          name="lookupDistrictId"
                                          hidden
                                          inputRef={register({ required: true })}
                                      />
                                      {errors.lookupDistrictId &&
                                          errors.lookupDistrictId.type === "required" && (
                                              <span className="error">Trường này là bắt buộc</span>
                                          )}
                                  </div>
                  
                              </div>

                              <div className="form-group row">
                                  {lookupCommuneModel && lookupCommuneModel.length > 0 && (
                                  <div className="col-6">
                                      <label className="text-dark">
                                          Phường/Xã
                                      </label>
                                      
                                          <Autocomplete
                                              multiple
                                              options={lookupCommuneModel}
                                              getOptionLabel={(option) => option.name}
                                              defaultValue={lookupCommuneModel.filter((item) => {
                                                  if (
                                                      planningModel &&
                                                      planningModel.communeIds &&
                                                      planningModel.communeIds.length > 0 &&
                                                      planningModel.communeIds.some(
                                                          (id) => id === item.id
                                                      )
                                                  ) {
                                                      return item;
                                                  }
                                              })}
                                              onChange={(event, newValue) => {
                                                  setLookupCommune(newValue);
                                              }}
                                              renderInput={(params) => (
                                                  <TextField
                                                      {...params}
                                                      variant="outlined"
                                                      size="small"
                                                  />
                                              )}
                                          />
                                      
                                  </div>
                                  )}
                                  <div className="col-6">
                                      <label className="text-dark">
                                          Cấp phê duyệt<span className="required"></span>
                                      </label>
                                      {approvalAgencyModel && approvalAgencyModel.length > 0 && (
                                          <Autocomplete
                                              options={approvalAgencyModel}
                                              getOptionLabel={(option) => option.name}
                                              defaultValue={approvalAgencyModel.find(
                                                  (item) =>
                                                      item.id === planningModel.approvalAgencyLevelId
                                              )}
                                              onChange={(event, newValue) => {
                                                  setApprovalAgency(newValue);
                                              }}
                                              disableClearable={true}
                                              renderInput={(params) => (
                                                  <TextField
                                                      {...params}
                                                      name="approvalAgencyId"
                                                      inputRef={register({ required: true })}
                                                      error={
                                                          errors.approvalAgencyId &&
                                                          errors.approvalAgencyId.type === "required"
                                                      }
                                                      variant="outlined"
                                                      size="small"
                                                  />
                                              )}
                                          />
                                      )}
                                      {errors.approvalAgencyId &&
                                          errors.approvalAgencyId.type === "required" && (
                                              <span className="error">Trường này là bắt buộc</span>
                                          )}
                                  </div>
                              </div>

                <div className="form-group row">
                  <div className="col-6">
                    <label className="text-dark">
                      Trạng thái<span className="required"></span>
                    </label>

                    {planningStatusModel && planningStatusModel.length > 0 && (
                      <Autocomplete
                        id="planningStatusId"
                        options={planningStatusModel}
                        getOptionLabel={(option) => option.name}
                        value={
                          planningStatus ||
                            planningStatusModel.some(
                              (item) => item.id === planningModel.planningStatusId
                            )
                            ? planningStatusModel.find(
                              (item) =>
                                item.id === planningModel.planningStatusId
                            )
                            : null
                        }
                        onChange={(event, newValue) => {
                          setPlanningStatus(newValue);
                        }}
                        disableClearable={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="planningStatusId"
                            inputRef={register({ required: true })}
                            error={
                              errors.planningStatusId &&
                              errors.planningStatusId.type === "required"
                            }
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    )}
                    {errors.planningStatusId &&
                      errors.planningStatusId.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                      )}
                  </div>

                                  <div className="col-3 form-group">
                                      <FormControlLabel
                                          control={<Checkbox checked={isActive} onChange={handleChangeStatus} name="isActive" />}
                                          label="Kích hoạt"
                                      />
                                  </div>
                                  <div className="col-3 form-group">
                                      <FormControlLabel
                                          control={<Checkbox checked={isShowHomepage} onChange={handleChangeShowHomepage} name="isShowHomepage" />}
                                          label="Hiển thị trang chủ"
                                      />
                                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-6">
                    <label className="text-dark">
                      File Tif
                      </label>
                    <TextField
                      fullWidth
                      type="text"
                      name="tifFileName"
                      value={tifName || planningModel.tifName}
                      placeholder='Hãy tải file tif lên.'
                      disabled
                      variant="outlined"
                      size="small"
                    />
                    <div className="mt-2">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onOpenSelectTifFile}
                      >
                        Chọn file Tif
                    </Button>
                    </div>
                  </div>
                  <div className="col-6">
                    <label className="text-dark">
                      File Zip
                      </label>
                    <TextField
                      fullWidth
                      type="text"
                      name="zipFileName"
                      value={zipName || planningModel.shpName}
                      placeholder='Hãy tải file zip lên.'
                      disabled
                      variant="outlined"
                      size="small"
                    />
                    <div className="mt-2">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onOpenSelectZipFile}
                      >
                        Chọn file zip
                    </Button>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="text-dark">Ghi chú</label>

                  <textarea
                    name="note"
                    rows="5"
                    className="form-control"
                    defaultValue={planningModel.note}
                                      maxLength='1000'
                                      ref={register({
                                          maxLength: 1000,
                                      })}
                                      error={
                                          (errors.note &&
                                              errors.note.type === "maxLength")
                                      }
                                  ></textarea>
                                  {errors.note &&
                                      errors.note.type === 'maxLength' && (
                                          <span className='error'>Trường này không quá 1000 ký tự</span>
                                      )}
                </div>

                <div className="form-group">
                  <div className="row justify-content-between align-items-end">
                    <div className="col-6 col-md-4">
                      <label className="text-dark">
                        Ảnh<span className="required"></span>
                      </label>
                      {!isShow &&
                        files &&
                        files.length > 0 &&
                        files.map((item) => (
                          <div key={item.fileName} style={{ width: "150px" }}>
                            <img
                              src={config.APIUrlDefault + item.filePreview}
                              alt={item.fileName}
                              className="img-fluid mb-2"
                              style={{
                                width: "auto",
                                height: "auto",
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                            />
                          </div>
                        ))}
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={onOpenSelectFile}
                        >
                          Chọn file
                        </Button>
                        <TextField
                          inputRef={register({ required: true })}
                          type="hidden"
                          name="image"
                          value={
                            (files && files.length > 0 && files[0].fileName) || ""
                          }
                        />
                        {errors.image && errors.image.type === "required" && (
                          <p className="error">Trường này là bắt buộc</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
            )}

            <DialogActions className="border-top">
              <Button
                type="button"
                onClick={onClose}
                variant="contained"
                startIcon={<CloseIcon />}
              >
                Hủy
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Lưu
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}

      {isShow && (
        <Dialog
          onClose={onCloseSelectFile}
          open={isShow}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={files}
              setFiles={setFiles}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {files && files.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {isShowTif && (
        <Dialog
          onClose={onCloseSelectTifFile}
          open={isShowTif}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectTifFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={tifFiles}
              setFiles={setTifFiles}
              acceptedFiles={["tif"]}
              isShowLeft={(false)}
              isShowAddFolder={(false)}
              isShowDownload={(true)}
              typeFile={'TIF'}
              cgisId={cgisId}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectTifFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {tifFiles && tifFiles.length > 0 && (tifFiles !== tifFilesTemp) && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectTifFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {isShowZip && (
        <Dialog
          onClose={onCloseSelectZipFile}
          open={isShowZip}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectZipFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={zipFiles}
              setFiles={setZipFiles}
              acceptedFiles={["zip"]}
              isShowLeft={(false)}
              isShowAddFolder={(false)}
              isShowDownload={(true)}
              typeFile={'ZIP'}
              cgisId={cgisId}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectZipFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {zipFiles && zipFiles.length > 0 && (zipFiles !== zipFilesTemp) && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectZipFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditRecordsManagement);
