import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Configs } from "../../common/config";
import { useForm } from "react-hook-form";

//--- Material Control
import {
  Button,
  TextField,
  IconButton,
  Tooltip,
  Popover,
  Select,
  MenuItem,
  Checkbox,
  Fab,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Divider from "@material-ui/core/Divider";

//--- Styles
import "./project.scss";

//--- Material Icon
import DeleteCircle from "@material-ui/icons/DeleteForever";
import AddCircle from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import RefreshIcon from "@material-ui/icons/Refresh";

//--- Component
import ListProject from "./list-project/list-project.view";
import AddProject from "./add-project/add-project.view";
import EditProject from "./edit-project/edit-project.view";
import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";
import ViewFile from "./view-project/view-project.view";

//--- Notifications
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";
import * as viVN from "../../language/vi-VN.json";

//--- Redux store
import * as projectActions from "../../redux/store/project-management/project-store";
import { makeStyles } from "@material-ui/core/styles";
import { is } from "immutable";
import { useHistory } from "react-router-dom";
import { UrlCollection } from "./../../common/url-collection";
import * as appActions from "../../core/app.store";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Project(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { langId } = useSelector((state) => state.language);
  const { id } = props.match.params;
  const { pathname } = props.location;
  const [projectModels, setProjectModels] = useState(null);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [searchData, setSearchData] = useState({
    keyword: "",
    status: 2,
    projectName: "",
  });

  const [projectId, setProjectId] = useState("");
  const [newsxmin, setNewsxmin] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("modifiedDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(Configs.DefaultPageSize);
  const [filePreview, setFilePreview] = useState([]);

  const [isDelete, setIsDelete] = useState(false);
  const [isShowTrash, setIsShowTrash] = useState(false);

  const { register, handleSubmit } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let _langId = langId;
    let _page = page;
    let _rowsPerPage = rowsPerPage;
    let _order = order;
    let _orderBy = orderBy;
    let _projectName = undefined;
    let _isDelete = isDelete;
    let _keyword = undefined;
    if (queryParams.get("langId")) {
      _langId = queryParams.get("langId");
    }
    if (queryParams.get("pageIndex")) {
      _page = queryParams.get("pageIndex");
      setPage(Number(_page));
    }
    if (queryParams.get("pageSize")) {
      _rowsPerPage = queryParams.get("pageSize");
      setRowsPerPage(Number(_rowsPerPage));
    }
    if (queryParams.get("keyword")) {
      _keyword = queryParams.get("keyword");
      setSearchData({ ...searchData, keyword: _keyword });
    }
    if (queryParams.get("projectName")) {
      _projectName = queryParams.get("projectName");
      setSearchData({ ...searchData, projectName: _projectName });
    }
    if (queryParams.get("order")) {
      _order = queryParams.get("order");
      setOrder(_order);
    }
    if (queryParams.get("orderBy")) {
      _orderBy = queryParams.get("orderBy");
      setOrderBy(_orderBy);
    }
    if (queryParams.get("isDelete")) {
      _isDelete = queryParams.get("isDelete");
      setIsDelete(_isDelete);
    }
    dispatch(appActions.ShowLoading(true))
    projectActions
      .GetListAll(
        _langId,
        Number(_page) + 1,
        _rowsPerPage,
        _orderBy + " " + _order,
        _keyword,
        _isDelete,
        _projectName
      )
      .then((res) => {
        dispatch(appActions.ShowLoading(false))
        setProjectModels(res.content.items);
        setTotalItemCount(res.content.totalItemCount);
      });
  }, [pathname]);

  const GetListAll = (
    langId,
    pageIndex,
    pageSize,
    sortExpression,
    keyword = "",
    isDelete
  ) => {
    setPage(pageIndex - 1);
    projectActions
      .GetListAll(
        langId,
        pageIndex,
        pageSize,
        sortExpression,
        searchData.keyword !== "" ? searchData.keyword : undefined,
        isDelete,
        searchData.projectName && searchData.projectName !== ""
          ? searchData.projectName
          : undefined
      )
      .then((res) => {
        setProjectModels(res.content.items);
        setTotalItemCount(res.content.totalItemCount);
      })
      .catch((err) => {
        /* inorge error */
      });
  };

  //--- Dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openViewFileDialog, setOpenViewFileDialog] = useState(false);
  const handleOpenAdd = () => {
    const param = getParamSearch();
    history.push(UrlCollection.AddProjectManagement + param);
  };

  const handleOpenEdit = (projectId) => {
    const param = getParamSearch();
    setProjectId(projectId);
    history.push(UrlCollection.EditProjectManagement + projectId + param);
  };
  const getParamSearch = () => {
    let param = `?langId=${langId}`;
    if (page) {
      param = param + `&pageIndex=${page}`;
    }
    if (rowsPerPage) {
      param = param + `&pageSize=${rowsPerPage}`;
    }
    if (searchData?.keyword) {
      param = param + `&keyword=${searchData.keyword}`;
    }
    if (searchData?.projectName) {
      param = param + `&projectName=${searchData.projectName}`;
    }
    param = param + `&orderBy=${orderBy}`;
    param = param + `&order=${order}`;
    param = param + `&isDelete=${isDelete}`;
    return param;
  };
  const handleOpenViewFileDialog = (event, id) => {
    const newArr = [...projectModels].filter((item) => item.id === id);
    setOpenViewFileDialog(true);
    setFilePreview(newArr);
  };
  const handleCloseViewFileDialog = (event) => {
    setOpenViewFileDialog(false);
    setFilePreview([]);
  };

  const handleOpenDeleteDialog = (projectId, newsxmin) => {
    setProjectId(projectId);
    setNewsxmin(newsxmin);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  //--- Filter
  const [filterSection, setFilterSection] = useState(null);

  const handleClickFilter = (event) => {
    setFilterSection(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterSection(null);
  };

  const ShowNotificationError = (messages) => {
    ShowNotification(messages, NotificationMessageType.Error);
  };

  const openFilter = Boolean(filterSection);
  const idFilter = openFilter ? "popoverSlider" : undefined;
  const handleRestore = (id) => {
    // newsAction.RestoreNews(id).then(
    //   (res) => {
    //     if (res && res.content && res.content.status) {
    //       GetListAll(
    //         searchData.categoryId,
    //         searchData.title,
    //         undefined,
    //         undefined,
    //         isDelete,
    //         searchData.status,
    //         1,
    //         rowsPerPage,
    //         orderBy + ' ' + order
    //       );
    //       ShowNotification(
    //         viVN.Success.NewsRestoreSuccess,
    //         NotificationMessageType.Success
    //       );
    //     }
    //   },
    //   (err) => {
    //     err &&
    //       err.errorType &&
    //       ShowNotification(
    //         viVN.Errors[err.errorType],
    //         NotificationMessageType.Error
    //       );
    //   }
    // );
  };
  const handleDelete = () => {
    projectActions.ProjectDelete(projectId).then(
      (res) => {
        if (res && res.content && res.content.status) {
          GetListAll(
            langId,
            1,
            rowsPerPage,
            orderBy + " " + order,
            id,
            searchData.keyword,
            isDelete,
            searchData.projectName && searchData.projectName !== ""
              ? searchData.keyword
              : undefined
          );
          handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.NewsDeleteSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };

  const handelChangeFeature = (projectId, value) => {
    alert("change " + projectId + " feature to" + value);
  };
  const handleClearAllField = () => {
    refresh();
    setFilterSection(null);
  };

  const onSubmit = async (data) => {
    await GetListAll(
      langId,
      1,
      rowsPerPage,
      orderBy + " " + order,
      searchData.keyword !== "" ? searchData.keyword : undefined,
      isDelete,
      searchData.projectName && searchData.projectName !== ""
        ? searchData.projectName
        : undefined
    );
    handleCloseFilter();
  };

  const refresh = () => {
    setSearchData({
      isHot: false,
      keyword: "",
      isHomePage: "",
      status: 2,
      projectName: "",
    });
    setOrderBy("modifiedDate");
    setOrder("desc");
    GetListAll(
      langId,
      1,
      rowsPerPage,
      orderBy + " " + order,
      undefined,
      isDelete,
      undefined
    );
  };

  const handleChangeTrash = (event) => {
    setIsShowTrash(event.target.checked);
    setIsDelete(event.target.checked);
    GetListAll(
      langId,
      1,
      rowsPerPage,
      orderBy + " " + order,
      undefined,
      event.target.checked,
      undefined
    );
  };

  const handleChangeSearchForm = (event) => {
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  const handleChangeChecked = (event) => {
    event.persist();
    setSearchData({ ...searchData, [event.target.name]: event.target.checked });
  };

  const handleEmptyTrash = () => {
    projectActions.EmptyTrash().then(
      (res) => {
        if (res && res.content && res.content.status) {
          GetListAll(
            langId,
            1,
            rowsPerPage,
            orderBy + " " + order,
            undefined,
            isDelete,
            undefined
          );
          //handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.NewsDeleteSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };

  return (
    <div className="slider">
      <div className="d-sm-flex align-items-center justify-content-between mb-3">
        <h1 className="h3 mb-0 text-gray-800">
          Thông tin dự án
          <Tooltip title="Làm mới">
            <Fab
              color="primary"
              aria-label="filter"
              size="small"
              onClick={refresh}
              className="ml-2"
            >
              <RefreshIcon />
            </Fab>
          </Tooltip>
          <span>&nbsp;&nbsp;</span>
          <FormControlLabel
            control={
              <Switch
                checked={isShowTrash}
                onChange={handleChangeTrash}
                name="checkedB"
                color="primary"
              />
            }
            label="Thùng rác"
          />
        </h1>
        <ButtonGroup
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          {isShowTrash && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleEmptyTrash}
              startIcon={<DeleteCircle />}
            >
              Xóa thùng rác
            </Button>
          )}
          
        </ButtonGroup>
        <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAdd}
            startIcon={<AddCircle />}
          >
            Thêm dự án
          </Button>
      </div>
      <div className="d-flex justify-content-between align-items-end">
        <form id="formSearch" onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex align-items-end">
            <div className="form-group">
              <TextField
                className="w-100"
                name="projectName"
                placeholder="Tiêu đề"
                variant="outlined"
                value={searchData.projectName}
                onChange={handleChangeSearchForm}
                inputRef={register}
              />
            </div>
            <div className="form-group">
              <TextField
                className="w-100 ml-2"
                name="keyword"
                variant="outlined"
                placeholder="Từ khoá"
                value={searchData.keyword}
                onChange={handleChangeSearchForm}
                inputRef={register}
              />
            </div>
            <div className="form-group ml-2">
              <Button variant="contained" color="primary" onClick={onSubmit} className="ml-2">
                <SearchIcon fontSize="small" /> Tìm kiếm
              </Button>
              <Button
                variant="contained"
                className="ml-2"
                onClick={handleClearAllField}
              >
                <ClearAllIcon fontSize="small" /> Bỏ lọc
              </Button>
            </div>
          </div>
        </form>
        <div className="form-group">
       
        </div>
      </div>
      {projectModels ? (
        <ListProject
          totalItemCount={totalItemCount}
          projectModels={projectModels}
          GetListAll={GetListAll}
          title={searchData.title}
          categoryId={searchData.categoryId}
          isDelete={isDelete}
          editAction={handleOpenEdit}
          viewFileAction={handleOpenViewFileDialog}
          deleteAction={handleOpenDeleteDialog}
          restoreAction={handleRestore}
          changeFeatureAction={handelChangeFeature}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          order={order}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
          langId={langId}
        />
      ) : (
        ""
      )}
      {openDeleteDialog && (
        <DeleteDialog
          isOpen={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onSuccess={handleDelete}
          header={"Xác nhận xóa"}
          content={"Bạn có chắc chắn muốn xóa?"}
        />
      )}
      {openViewFileDialog && (
        <ViewFile
          isOpen={openViewFileDialog}
          onClose={handleCloseViewFileDialog}
          filePreview={filePreview}
        />
      )}
    </div>
  );
}
