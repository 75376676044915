export const UrlCollection = {
  Home: '/',
  Slider: '/bai-viet-trang-chu',
  PlanningAnnouncementProcess: '/quy-trinh-cong-bo-quy-hoach',
  EmailTemplate: '/email-template',
  Notifications: '/notifications',
  RoleManagement: '/role-management',
  ContactManagement: '/contact-management',
  EmailGenerated: '/email-generated',
  UserManagement: '/quan-ly-nguoi-dung',
  User_Admin_Management: '/quan-ly-nguoi-dung-admin',
  CommuneManagement: '/quan-ly-xa-phuong',
  DistrictManagement: '/quan-ly-quan-huyen',
  ProvinceManagement: '/quan-ly-thanh-pho',
  LinkGroup: '/danh-sach-nhom',
  ServiceLink: '/danh-sach-lien-ket',
  UserLogHistory: '/nhat-ky-nguoi-dung',
  LandType: '/quan-ly-loai-dat',
  LandTypeDetail: '/quan-ly-chi-tiet-loai-dat',
  News: '/tin-tuc',
  AddPosts: '/tin-tuc/:id/:postTypeId/them-moi',
  EditPosts: '/tin-tuc/:id/:postTypeId/:postId/chinh-sua',
  Posts: '/tin-tuc/:id/:postTypeId',
  Address: '/dia-chi',
  AddClassified: '/mua-ban-bat-dong-san/them-moi',
  EditClassified: '/mua-ban-bat-dong-san/chinh-sua/',
  Classified: '/mua-ban-bat-dong-san',
    LandPriceInfo: '/thong-tin-gia-dat',
    StatePriceFile: '/gia-dat-nha-nuoc',
  LimitAccess: '/black-list-white-list-IP',
  ContactToSaler: '/lien-he-nguoi-ban',

  //-AnsQues
  Faq: '/hoi-dap',

  // MetaTag
  MetaTag: '/meta-tag',
  EditMetaTag: '/meta-tag/chinh-sua/',
  AddMetaTag: '/meta-tag/them-moi',

  // MobileAppIntroduce
  MobileAppIntroduce: '/thong-tin-gioi-thieu-mobile-app',

  // Introduce
  Introduce: '/thong-tin-gioi-thieu',
  AddIntroduce: '/thong-tin-gioi-thieu/them-moi',
  EditIntroduce: '/thong-tin-gioi-thieu/chinh-sua/',

  // Consulting Services
  ConsultingServices: '/dich-vu-tu-van',

  //Investor
  Investor: '/chu-dau-tu',

  //---> Project management
  ProjectManagement: '/quan-ly-du-an',
  AddProjectManagement: '/quan-ly-du-an/them-moi',
  EditProjectManagement: '/quan-ly-du-an/chinh-sua/',
  //--- Account
  Login: '/dang-nhap',
  Register: '/dang-ky',
  ForgotPassword: '/quen-mat-khau',
  ConfirmCode: '/xac-nhan',
  ResetPassword: '/dat-lai-mat-khau',
  MapData: '/ban-do',
  CreateMapData: '/thiet-dat-thong-so-ban-do/:mapKey',
  AccessDenied: '/403',
  OpinionForm: '/tao-form-y-kien',
  MyAccount: '/quan-ly-tai-khoan',

  //--- Table structure
  TableStructure: '/sua-cau-truc-bang',

  //--- Google map
  GoogleMap: '/demo-google-map',
};
