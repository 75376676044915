import { IconButton, TextField, Tooltip } from "@material-ui/core";
import React, { Component } from "react";
import FormInputCurrency from "../../../components/FormItem/FormInputCurrency";
import DeleteIcon from "@material-ui/icons/Delete";
import "./style.scss";
function PriceMonth(props) {
  const {landPriceMonthy, onChangePriceMonth} = props;
  return (
    <div className="container-history-price" >
      <div className="form-group row">
        <div className="col-12 col-md-2 col-lg-2">
          <label className="text-dark">Lãi suất chính</label>
          <TextField
            type="number"
            color="primary"
            name="interest"
            value={landPriceMonthy.interest}
            onChange={e=>onChangePriceMonth('interest', e.target.value)}
            className="form-control p-0 mt-0"
          />
        </div>
        <div className="col-12 col-md-2 col-lg-2">
          <label className="text-dark">Bảo hiểm thế chấp</label>
          <TextField
            name="loanInsurance"
            fullWidth
            value={landPriceMonthy.loanInsurance}
            type="number"
            className="form-control"
            onChange={e=>onChangePriceMonth('loanInsurance', e.target.value)}
          />
        </div>
        <div className="col-12 col-md-2 col-lg-2">
          <label className="text-dark">Thuế tài sản</label>
          <TextField
            name="taxPrice"
            fullWidth
            type="number"
            value={landPriceMonthy.taxPrice}
            className="form-control"
            onChange={e=>onChangePriceMonth('taxPrice', e.target.value)}
          />
        </div>
        <div className="col-12 col-md-2 col-lg-2">
          <label className="text-dark"> Bảo hiểm nhà ở </label>
          <TextField
            name="landInsurance "
            fullWidth
            type="number"
            value={landPriceMonthy.landInsurance}
            className="form-control"
            onChange={e=>onChangePriceMonth('landInsurance', e.target.value)}
          />
        </div>
        <div className="col-12 col-md-2 col-lg-2">
          <label className="text-dark">Phí </label>
          <TextField
            name="fee"
            fullWidth
            type="number"
            value={landPriceMonthy.fee}
            className="form-control"
            onChange={e=>onChangePriceMonth( 'fee', e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

export default PriceMonth;
