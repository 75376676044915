//--- Layout
import LayoutView from './components/layout/layout.view';
import LayoutUserView from './components/layout/layout-user.view.jsx';
import Login from './modules/login/login.view.jsx';

//--- Home
import Home from './modules/home/home.view.jsx';

//--- Consult the community
import EmailTemplate from './modules/email-template/email-template';
import RoleManagement from './modules/role-management/role-management';
import ContactManagement from './modules/contact-management/contact-management.view';
import EmailGenerated from './modules/email-generated/email-generated.view';
import UserManagement from './modules/user-management/user-management.view';
import UserAdminManagement from './modules/user-admin-management/user-management.view';
import CommuneManagement from './modules/communce-management/communce-management.view';
import ProviceManagement from './modules/province-management/provice-management.view';
import DistrictManagement from './modules/district-management/district-management.view';
import UserLogHistory from './modules/user-log/user-log-history.view';
import LandTypeManagement from './modules/land-type-management/land-type-management.view';
import LandTypeDetailManagement from './modules/land-type-detail-management/land-type-detail-management.view';
import MyAccount from './modules/my-account/my-account.view';
import LimitAccess from './modules/limit-access-management/limit-access-managemnet.view';
import { UrlCollection } from './common/url-collection';

//Classified
import Classified from './modules/classified-management/classified.view';

//--- Slider
import Slider from './modules/slider/slider.view.jsx';

//--Faq
import Faq from './modules/faq-management/faq.view';
//--Investor
import Investor from './modules/investor-management/investor.view';
//--- News
import News from './modules/news/news.view.jsx';
import Posts from './modules/post-management/posts.view';
//--- Address
import Address from './modules/address-management/address.view';

//--- Map
import MapData from './modules/map-data/map-data.view.jsx';
import InitMapDataView from './modules/init-map-data/init-map-data';

//--- Records management
import RecordsManagement from './modules/planning-announcement-process/records-management/records-management.view.jsx';

//--- Link
import LinkGroupManagementView from './modules/link-group/link-group-management.view.jsx';
import ServiceLinkManagementView from './modules/service-links/service-link-management.view';

//--- Opinion form
import OpinionForm from './modules/opinion-form/opinion-form.view.jsx';

//--- Table Layer Structure
import TableStructure from './modules/table-structure/table-structure.view.jsx';

//--- Access denied
import AccessDenied from './modules/access-denied/access-denied.view.jsx';
import DemoFormInputAddress from './components/FormItem/DemoFormInputAddress.js';
import LandPriceInfo from './modules/land-price-info-management/land-price-info.view.jsx';
//--- Project
import Project from './modules/project-management/project.view';
//--- MetaTag
import { MetaTag } from './modules/meta-tag/meta-tag.view.jsx';
//--- MobileAppIntroduce
import { MobileAppIntroduce } from './modules/mobile-app-introduce/mobile-app-introduce.view.jsx';
//--- Introduce
import { Introduce } from './modules/introduce/introduce.view';
//--- Introduce
import { ConsultingServices } from './modules/consulting-services/consultingServices.view';
import  StatePriceFile  from './modules/state-price-file-management/state-price-file.view';
import AddProject from './modules/project-management/add-project/add-project.view';
import EditProject from './modules/project-management/edit-project/edit-project.view';
import AddClassified from './modules/classified-management/add-classified/add-classified.view';
import EditClassified from './modules/classified-management/edit-classified/edit-classified.view';
import AddNews from './modules/post-management/add-posts/add-posts.view';
import EditSlider from './modules/post-management/edit-posts/edit-posts.view';
import AddOrEditMetaTagDialog from './modules/meta-tag/addOrEditMetaTagDialog';
import AddOrEditIntroduceDialog from './modules/introduce/addOrEditIntroduceDialog';
import EditIntroduce from './modules/introduce/EditIntroduce';
import EditMeta from './modules/meta-tag/EditMeta';
import Notification from './modules/notification/notification';
import contactSalerView from './modules/contact-saler/contact-saler.view';

const routes = [
  {
    layout: LayoutView,
    component: Home,
    path: UrlCollection.Home,
  },
  {
    layout: LayoutView,
    component: Slider,
    path: UrlCollection.Slider,
  },
  {
    layout: LayoutView,
    component: Posts,
    path: UrlCollection.Posts,
  },
  {
    layout: LayoutView,
    component: AddNews,
    path: UrlCollection.AddPosts,
  },
  {
    layout: LayoutView,
    component: EditSlider,
    path: UrlCollection.EditPosts,
  },
  {
        layout: LayoutView,
        component: StatePriceFile,
        path: UrlCollection.StatePriceFile,
  },
  {
    layout: LayoutUserView,
    component: Login,
    path: UrlCollection.Login,
  },
  {
    layout: LayoutView,
    component: Investor,
    path: UrlCollection.Investor,
  },
  {
    layout: LayoutView,
    component: Faq,
    path: UrlCollection.Faq,
  },
  {
    layout: LayoutView,
    component: ConsultingServices,
    path: UrlCollection.ConsultingServices,
  },
  {
    layout: LayoutView,
    component: MetaTag,
    path: UrlCollection.MetaTag,
  },
  {
    layout: LayoutView,
    component: AddOrEditMetaTagDialog,
    path: UrlCollection.AddMetaTag,
  },
  {
    layout: LayoutView,
    component: EditMeta,
    path: UrlCollection.EditMetaTag + ":idMetatag",
  },
  {
    layout: LayoutView,
    component: MobileAppIntroduce,
    path: UrlCollection.MobileAppIntroduce,
  },
  {
    layout: LayoutView,
    component: Introduce,
    path: UrlCollection.Introduce,
  },
  {
    layout: LayoutView,
    component: AddOrEditIntroduceDialog,
    path: UrlCollection.AddIntroduce,
  },
  {
    layout: LayoutView,
    component: EditIntroduce,
    path: UrlCollection.EditIntroduce + ":idIntroduce",
  },
  {
    layout: LayoutView,
    component: Classified,
    path: UrlCollection.Classified,
  },
  {
    layout: LayoutView,
    component: AddClassified,
    path: UrlCollection.AddClassified,
  },
  {
    layout: LayoutView,
    component: EditClassified,
    path: UrlCollection.EditClassified + ":classId",
  },
  {
    layout: LayoutView,
    component: RecordsManagement,
    path: UrlCollection.PlanningAnnouncementProcess,
  },
  {
    layout: LayoutView,
    component: MapData,
    path: UrlCollection.MapData,
  },
  {
    layout: LayoutView,
    component: InitMapDataView,
    path: UrlCollection.CreateMapData,
  },
  {
    layout: LayoutView,
    component: Notification,
    path: UrlCollection.Notifications,
  },
  {
    layout: LayoutView,
    component: EmailTemplate,
    path: UrlCollection.EmailTemplate,
  },
  {
    layout: LayoutView,
    component: EmailGenerated,
    path: UrlCollection.EmailGenerated,
  },
  {
    layout: LayoutView,
    component: RoleManagement,
    path: UrlCollection.RoleManagement,
  },

  {
    layout: LayoutView,
    component: ContactManagement,
    path: UrlCollection.ContactManagement,
  },
  {
    layout: LayoutView,
    component: contactSalerView,
    path: UrlCollection.ContactToSaler,
  },
  {
    layout: LayoutView,
    component: UserManagement,
    path: UrlCollection.UserManagement,
  },

  {
    layout: LayoutView,
    component: UserAdminManagement,
    path: UrlCollection.User_Admin_Management,
  },

  {
    layout: LayoutView,
    component: CommuneManagement,
    path: UrlCollection.CommuneManagement,
  },
  {
    layout: LayoutView,
    component: DistrictManagement,
    path: UrlCollection.DistrictManagement,
  },

  {
    layout: LayoutView,
    component: ProviceManagement,
    path: UrlCollection.ProvinceManagement,
  },

  {
    layout: LayoutView,
    component: LinkGroupManagementView,
    path: UrlCollection.LinkGroup,
  },

  {
    layout: LayoutView,
    component: ServiceLinkManagementView,
    path: UrlCollection.ServiceLink,
  },
  {
    layout: LayoutView,
    component: UserLogHistory,
    path: UrlCollection.UserLogHistory,
  },

  {
    layout: LayoutView,
    component: OpinionForm,
    path: UrlCollection.OpinionForm,
  },

  {
    layout: LayoutView,
    component: LandTypeManagement,
    path: UrlCollection.LandType,
  },

  {
    layout: LayoutView,
    component: LandTypeDetailManagement,
    path: UrlCollection.LandTypeDetail,
  },

  {
    layout: LayoutView,
    component: TableStructure,
    path: UrlCollection.TableStructure,
  },

  {
    layout: LayoutView,
    component: AccessDenied,
    path: UrlCollection.AccessDenied,
  },

  {
    layout: LayoutView,
    component: MyAccount,
    path: UrlCollection.MyAccount,
  },

  {
    layout: LayoutView,
    component: Address,
    path: UrlCollection.Address,
  },

  {
    layout: LayoutView,
    component: DemoFormInputAddress,
    path: '/demo-form-input-address',
  },
  {
    layout: LayoutView,
    component: Project,
    path: UrlCollection.ProjectManagement,
  },
  {
    layout: LayoutView,
    component: AddProject,
    path: UrlCollection.AddProjectManagement,
  },
  {
    layout: LayoutView,
    component: EditProject,
    path: UrlCollection.EditProjectManagement + ":projectId",
  },
  {
    layout: LayoutView,
    component: LandPriceInfo,
    path: UrlCollection.LandPriceInfo,
  },
  {
    layout: LayoutView,
    component: LimitAccess,
    path: UrlCollection.LimitAccess,
  },
];

export default routes;
