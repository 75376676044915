import React from "react";
import "date-fns";
import { useForm } from "react-hook-form";

//--- Material Control
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import Popover from "@material-ui/core/Popover";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import RefreshIcon from "@material-ui/icons/Refresh";

function SearchContactManager(props) {
  const {
    getListContactModels,
    setName,
    setSendFrom,
    rowsPerPageCommon,
    setPage,
    name,
    sendFrom,
    setOrderBy,
    setOrder,
    orderBy,
    order,
  } = props;

  const { register, handleSubmit } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const [filterSection, setFilterSection] = React.useState(null);

  const handleClickFilter = (event) => {
    setFilterSection(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterSection(null);
  };

  const onSubmit = async (data) => {
    setPage(0);
    await getListContactModels(
      1,
      rowsPerPageCommon,
      orderBy + " " + order,
      data.name,
      data.sendFrom
    );
    handleCloseFilter();
  };

  const refresh = () => {
    setName("");
    setSendFrom("");
    setOrderBy("sentDate");
    setOrder("desc");
    let sortExpression = "sentDate desc";
    getListContactModels(1, rowsPerPageCommon, sortExpression, "", "");
  };

  const handleClearAllField = () => {
    setName("");
    setSendFrom("");
  };

  const openFilter = Boolean(filterSection);
  const idFilter = openFilter ? "popoverSlider" : undefined;

  return (
    <div className="">
      <div className="d-sm-flex align-items-center justify-content-between mb-3">
        <h1 className="h3 mb-0 text-gray-800">
          Liên hệ người bán
          
          <Tooltip title="Refresh">
            <Fab
              color="primary"
              aria-label="filter"
              size="small"
              onClick={refresh}
              className="ml-2"
            >
              <RefreshIcon />
            </Fab>
          </Tooltip>
      
        </h1>
      </div>
      <div className="d-flex justify-content-between align-items-end">
        <form id="formSearch" onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex align-items-end">
            <div className="form-group">
              <TextField
                className="w-100"
                variant="outlined"
                name="name"
                placeholder="Tên liên hệ"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                inputRef={register}
              />
            </div>
            <div className="form-group ml-4">
              <TextField
                className="w-100"
                variant="outlined"
                name="sendFrom"
                placeholder="Người gửi"
                    value={sendFrom}
                    onChange={(e) => {
                      setSendFrom(e.target.value);
                    }}
                inputRef={register}
              />
            </div>
            <div className="form-group ml-2">
              <Button variant="contained" color="primary" onClick={onSubmit} className="ml-2">
                <SearchIcon fontSize="small" /> Tìm kiếm
              </Button>
              <Button
                variant="contained"
                className="ml-2"
                onClick={handleClearAllField}
              >
                <ClearAllIcon fontSize="small" /> Bỏ lọc
              </Button>
            </div>
          </div>
        </form>
        <div className="form-group">
        
        </div>
      </div>
    </div>
  );
}

export default SearchContactManager;
