export const pageName = {
  HOME: "Trang chủ",
  PROJECTINFO: "Trang thông tin dự án",
  REALSTATEPRICE: "Trang giá đất",
  BUYNSALE: "Trang mua bán",
  FAQ: "Trang hỏi đáp",
  CONTACTUS: "Trang liên hệ",
  HELPING: "Trang hướng dẫn sử dụng",
  PLANNING: "Trang quy hoạch",
};
