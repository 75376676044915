import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Configs } from '../../../common/config';
import * as configuration from '../../../utils/configuration';
import dateformat from 'dateformat';

//--- Material Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import FooterPagination from '../../../components/footer-pagination/footer-pagination';
//--- Material Icon
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import VisibilityIcon from '@material-ui/icons/Visibility';

//--- Material Control
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { UserSidePathUrl } from '../../../common/config';
import Rating from "@material-ui/lab/Rating";
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: window.outerHeight - 365,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));
const RatingComponent = React.forwardRef(function MyComponent(props, ref) {
  //  Spread the props to the underlying DOM element.
  return (
    <div {...props} ref={ref}>
      <Rating name="simple-controlled" value={props?.valueRating} precision={0.1} readOnly />
    </div>
  );
});
const headCells = [
  { id: 'image_Url', hideSortIcon: true, label: 'Ảnh' },
    { id: 'title', hideSortIcon: false, label: 'Tiêu đề' },
    //{ id: 'description', hideSortIcon: false, label: 'Tóm tắt' },
  //{ id: "category.name", hideSortIcon: false, label: "Danh mục" },
  { id: "description", hideSortIcon: false, label: "Mô tả ngắn" },
  { id: "rating", hideSortIcon: false, label: "Điểm SEO" },
  { id: 'viewCount', hideSortIcon: false, label: 'Lượt xem' },
  { id: 'isActive', hideSortIcon: false, label: 'Kích hoạt' },
  { id: 'isHot', hideSortIcon: false, label: 'Tin tiêu biểu' },
  { id: 'isHomePage', hideSortIcon: false, label: 'Hiển thị trang chủ' },
    { id: 'modifiedDate', hideSortIcon: false, label: 'Ngày sửa' },
  { id: 'modifiedBy', hideSortIcon: false, label: 'Người sửa' },
  { id: 'actions', hideSortIcon: true, label: '' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, postTypeId } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headerCellFilter = headCells.filter((item) => {
    if (postTypeId === '5') {
      return item.id !== 'isHomePage' && item.id !== 'isHot';
    }
    return item;
  });

  return (
    <TableHead>
      <TableRow>
        {headerCellFilter.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className={
              'pt-3 pb-3 text-nowrap '
            }>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.hideSortIcon ? true : false}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ListNews(props) {
  const {
    viewFileAction,
    editAction,
    deleteAction,
    restoreAction,
    newsModels,
    totalItemCount,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    GetListAll,
    //title,
    categoryId,
    //isDelete,
    order,
    page,
    rowsPerPage,
    orderBy,
    postTypeId,
      langId,
      ctid,
  } = props;

  //--- Config table
  const classes = useStyles();
    const [linkToView, setLinkToView] = useState('');
    useEffect(() => {
        switch (postTypeId) {
            case "1":
                setLinkToView(configuration.DomainUserSide + UserSidePathUrl.planningNews);
                break;
            case "2":
                setLinkToView(configuration.DomainUserSide + UserSidePathUrl.projectNews);
                break;
            case "3":
                if (ctid === "3")
                    setLinkToView(configuration.DomainUserSide + UserSidePathUrl.flowInvestment);
                else if (ctid === "4")
                    setLinkToView(configuration.DomainUserSide + UserSidePathUrl.lawDocument);
                break;
            case "4":
                setLinkToView(configuration.DomainUserSide + UserSidePathUrl.News);
                break;
            default:
                break;
        }
        //console.log('liktoview',linkToView);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postTypeId, ctid]);

  //--- Handle sort, change page, change row per page
  const handleRequestSort = (event, property) => {
    if (property !== 'avatar') {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      let sort = isAsc ? 'desc' : 'asc';
      let sortExpression = property + ' ' + sort;

      GetListAll(
        postTypeId,
        langId,
        page + 1,
        rowsPerPage,
        sortExpression,
        categoryId,
        undefined,
        undefined,
        undefined,
        undefined
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage-1);
    let sortExpression = orderBy + ' ' + order;
    GetListAll(
      postTypeId,
      langId,
        newPage,
      rowsPerPage,
      sortExpression,
      categoryId,
      undefined,
      undefined,
      undefined,
      undefined
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    let sortExpression = orderBy + ' ' + order;
    GetListAll(
      postTypeId,
      langId,
      1,
        event.target.value,
      sortExpression,
      categoryId,
      undefined,
      undefined,
      undefined,
      undefined
    );
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, newsModels.length - page * rowsPerPage);

  const totalPage = Math.ceil(totalItemCount / rowsPerPage);
  const renderLabelRating = (row) => {
    let valueRating = 3 ;
    let showRating = false; 
    let labelRating = '';
    let tmp = '';
    if(!row.metaDescription) {
      showRating = true;
      tmp += 'mô tả ngắn SEO, ';
      valueRating -=1
    }
    if(!row.metaKeyword) {
      showRating = true;
      tmp += 'từ khóa, ';
      valueRating -=1
    }
    if(!row.metaTitle) {
      showRating = true;
      tmp += 'tiêu đề SEO';
      valueRating -=1
    }
    labelRating = 'Các trường '+ tmp + ' chưa có giá trị'
    valueRating = ((valueRating/3)*5).toFixed(1)
    return { labelRating, showRating , valueRating }
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size='small' stickyHeader>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={newsModels.length}
              postTypeId={postTypeId}
            />
            <TableBody>
              {newsModels && newsModels.length > 0 ? (
                newsModels.map((row, index) => {
                  const { labelRating, showRating , valueRating } = renderLabelRating(row)
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell>
                        <img
                          src={
                            configuration.APIUrlDefault +
                            row?.imageFile?.filePreview
                          }
                          onError={(e) =>
                            (e.target.src =
                              process.env.PUBLIC_URL + '/logo.png')
                          }
                          alt={row.title}
                          className='logo'
                        />
                      </TableCell>
                          <TableCell > {//className='MuiTableCell-freeze shadow-sm'
                          }
                              <a href={linkToView + row.slug + '-' + row.id} target="review" tooltip="Click để xem trước">{row.title}</a>
                        
                      </TableCell>
                          {/*<TableCell className={classes.des}>{row.description}</TableCell>*/}

                      {/*<TableCell>{row.category.name}</TableCell>*/}
                      <TableCell style={{ maxWidth: 300, minWidth: 300}}>
                        {row?.description}
                      </TableCell>
                      <TableCell className="text-center">
                        {
                          valueRating == 5.0 ? (
                            <RatingComponent valueRating={valueRating}></RatingComponent>
                          ) : <Tooltip title={labelRating} arrow>
                          <RatingComponent valueRating={valueRating}></RatingComponent>
                        </Tooltip>
                        }
                      
                      </TableCell>
                      <TableCell className='text-center'>
                        {row.viewCount}
                      </TableCell>
                      <TableCell className='text-center'>
                        {row.isActive ? (
                          <img
                            src={require('../../../assets/icon/tick.png')}
                            alt='Tick'
                          />
                        ) : (
                          <img
                            src={require('../../../assets/icon/cancel.png')}
                            alt='Cancel'
                          />
                        )}
                      </TableCell>
                      {postTypeId !== '5' && (
                        <>
                          <TableCell className='text-center'>
                            {row.isHot ? (
                              <img
                                src={require('../../../assets/icon/tick.png')}
                                alt='Tick'
                              />
                            ) : (
                              <img
                                src={require('../../../assets/icon/cancel.png')}
                                alt='Cancel'
                              />
                            )}
                          </TableCell>
                          <TableCell className='text-center'>
                            {row.isHomePage ? (
                              <img
                                src={require('../../../assets/icon/tick.png')}
                                alt='Tick'
                              />
                            ) : (
                              <img
                                src={require('../../../assets/icon/cancel.png')}
                                alt='Cancel'
                              />
                            )}
                          </TableCell>
                        </>
                      )}

                      <TableCell>
                              {dateformat(row.modified_date, 'dd/mm/yyyy')}
                      </TableCell>

                      <TableCell>
                        {row.modified_by}
                      </TableCell>
                      <TableCell align='right' className='text-nowrap'>
                        {postTypeId === '3' &&
                          configuration.checkEmptyObject(row.files) && (
                            <Tooltip title='Xem'>
                              <IconButton
                                aria-label='edit'
                                onClick={(e) => viewFileAction(e, row.id)}>
                                <VisibilityIcon className='text-warning' />
                              </IconButton>
                            </Tooltip>
                          )}

                        <Tooltip title='Sửa'>
                          <IconButton
                            aria-label='edit'
                            onClick={() => editAction(row.id)}>
                            <EditIcon className='text-primary' />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title='Xóa'>
                          <IconButton
                            aria-label='delete'
                            onClick={() => deleteAction(row.id, row.xmin)}>
                            <DeleteIcon className='text-danger' />
                          </IconButton>
                        </Tooltip>
                        {row.isDelete ? (
                          <Tooltip title='Khôi phục'>
                            <IconButton
                              aria-label='restore'
                              onClick={() => restoreAction(row.id)}>
                              <RestoreIcon className='text-success' />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          ''
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover tabIndex={-1}>
                  <TableCell colSpan={8} className='text-center'>
                    Không có dữ liệu
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 0 }}>
                  <TableCell
                    colSpan={8}
                    style={{ padding: 0, borderBottom: 0 }}
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {totalItemCount && totalItemCount > 0 ? (
          <FooterPagination
            currentPage={page+1}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            totalPage={totalPage}
          />
        ) : (
          ''
        )}
      </Paper>
    </div>
  );
}
