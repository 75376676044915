const env = process.env.NODE_ENV || 'development';

const apiEnvironment = {
  development: {
    api: 'https://mailand-api.cgis.asia/',
    domainAdminSide: 'http://localhost:3000',
    domainUserSide: 'https://mailand.cgis.asia',
    domainName: '',
  },
  production: {
    api: 'https://mailand-api.cgis.asia/',
    domainAdminSide: 'https://admin.mailand.cgis.asia',
    domainUserSide: 'https://mailand.cgis.asia',
    domainName: 'mailand.cgis.asia',
  },
};

module.exports = apiEnvironment[env];
