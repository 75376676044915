import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Controller, useFormContext } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import * as addressActions from '../../../redux/store/address/address.store';
import AddAddressStandAlone from './components/add-addresses-standalone.view';

import './styles.scss';

const FormInputAddress = ({
  title,
  name,
  validate,
  placeholder = 'Chọn...',
  options = [],
  className = 'form-control',
  titleClassName = 'text-dark',
  langId = 'vi',
  InputProps = {
    readOnly: false,
  },
  //setAddresId,
  ...rest
}) => {
  const { control, errors, setValue } = useFormContext();
  const [isOpenAddAddressDialog, setIsOpenAddAddressDialog] = useState(false);
  const [isShowButtonAddAddress, setIsShowButtonAddAddress] = useState(false);

  const handleCloseAddAddressDialog = () => {
    setIsOpenAddAddressDialog(false);
  };

  const handleAddAddressSuccess = () => {
    setIsOpenAddAddressDialog(false);
    setIsShowButtonAddAddress(false);
  };

  const filterAddresses = (inputValue) => {
    return addressActions
      .GetLookUpAddress(langId, inputValue, 20)
      .then((res) => {
        if (res?.content && res?.content.length > 0) {
          res.content.forEach((element) => {
            element.value = element.id;
            element.label = element.name;
          });
          //setAddresId(res?.content[0].id);
        } else {
          setIsShowButtonAddAddress(true);
        }
        return res.content;
      })
      .catch((err) => console.log(err));
  };

  const promiseOptions = (inputValue) => {
    setIsShowButtonAddAddress(false);
    if (inputValue.length >= 5) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(filterAddresses(inputValue));
        }, 1000);
      });
    }
  };
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={validate}
        placeholder={placeholder}
        render={({ onChange, name, value }) => (
          <>
             {title ? <label className={titleClassName}>
              {title}
              {validate?.required ? <span className='required'></span> : null}
            </label> : "" }
            <div className='input-address-group'>
              <AsyncSelect
                cacheOptions
                defaultOptions={options}
                loadOptions={promiseOptions}
                onChange={onChange}
                value={value}
                isClearable
                placeholder={placeholder}
                closeMenuOnSelect={true}
                {...rest}
              />

              {isShowButtonAddAddress && (
                <Button
                  variant='contained'
                  endIcon={<AddOutlined />}
                  size='small'
                  onClick={() => setIsOpenAddAddressDialog(true)}>
                  Thêm địa chỉ mới
                </Button>
              )}
              {isOpenAddAddressDialog && (
                <AddAddressStandAlone
                  isOpen={isOpenAddAddressDialog}
                  onClose={handleCloseAddAddressDialog}
                  onSuccess={handleAddAddressSuccess}
                  langId={langId}
                  setFieldValue={setValue}
                  fieldName={name}
                  //setAddresId={setAddresId}
                />
              )}
            </div>
            {validate && errors[name] ? (
              <span className='error'>
                {errors[name] && errors[name].message}
              </span>
            ) : null}
          </>
        )}
      />
    </>
  );
};

export default FormInputAddress;
