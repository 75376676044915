import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import SunEditor from "suneditor-react";
import { ApiUrl } from "../../../api/api-url";

//--- Styles
import { DropzoneArea } from "material-ui-dropzone";
//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  APIUrlDefault,
  MaxSizeImageUpload,
} from "../../../utils/configuration";
import * as configCommon from "../../../common/config";
import NumberFormatCustom from "../../../components/NumberFormatCustom/NumberFormat";
import * as viVN from "../../../language/vi-VN.json";
import { formatDateToSubmit } from "../../../utils/helpers";

//--- Action
//import * as newsAction from '../../../redux/store/news/news.store';
import * as ProjectActions from "../../../redux/store/project-management/project-store";
import * as MasterActions from "../../../redux/store/master-data/master-data.store";
import * as ClassifiedActions from "../../../redux/store/classified/classified.store";
import FileManagement from "../../../components/file_management/file_management";
import FormInputAddress from "../../../components/FormItem/FormInputAddress";
import FormInputCurrency from "../../../components/FormItem/FormInputCurrency";
import FormAsyncSelecPagination from "../../../components/FormItem/FormAsynceSelectPaginationProject";
import FormAsyncPlanning from "../../../components/FormItem/FormAsynceSelectPaginationPlanning";
import FileInputComponent from "../../../components/file-input/file-input.view";
import { convertNumberToCurrency } from "../../../utils/helpers";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { UrlCollection } from "../../../common/url-collection";
import { ArrowBackIos } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import HistoryPriceView from "./history-price.view";
import HistoryTaxView from "./history-tax.view";
import PriceMonth from "./price-month.view";
const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
  closeButtonImage: {
    position: "absolute",
    right: "2px",
    top: "3px",
    color: theme.palette.grey[500],
    opacity: "0.7",
    background: "white",
    borderRadius: "50%",
    cursor: "pointer",
  },
}));
function uuidTmp() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
export default function EditClassified(props) {
  const classes = useStyles();
  const history = useHistory();
  const { langId } = useSelector((state) => state.language);
  const { classId } = props.match.params;
  //const [image_Url, setImage_Url] = useState('');
  const [classDetail, setClassDetail] = useState();
  const [isShow, setShow] = useState(false);
  const [isShowFileLib, setShowFileLib] = useState(false);

  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);

  const [fileLib, setFileLib] = useState([]);
  const [fileTempLib, setFileTempLib] = useState([]);

  const [arrayOfLib, setArrayOfLib] = useState([]);
  const [content, setContent] = useState();
  const [optionProject, setOptionProject] = useState([]);
  const [optionRealEstate, setOptionRealEstate] = useState([]);
  const [optionDirection, setOptionDirection] = useState([]);
  const [direction, setDirection] = useState({});
  const [estate, setEstate] = useState({});
  const [project, setProject] = useState({});
  const [isActive, setActive] = useState(false);
  const [isShowUi, setShowUi] = useState(false);
  const [isSoldOut, setSoldOut] = useState(false);
  const [expire, setExpire] = useState("");
  const [fileUpload, setFileUpload] = useState([]);
  const [fileUploadGalary, setFileUploadGalary] = useState([]);
  const [tab, setTab] = useState(1);
  const [listUUIDHistoryPrice, setListUUIDHistoryPrice] = useState([]);
  const [listUUIDHistoryTax, setListUUIDHistoryTax] = useState([]);
  const [landPriceMonthy, setLandPriceMonthy] = useState({});
  const urlUploadImage = `${APIUrlDefault}${ApiUrl.UrlUploadFromEditor}`;

  const methods = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    defaultValues: {
      price: 0,
      pricePerMettre: 0,
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    errors,
    clearErrors,
    setValue,
    formState,
  } = methods;
  const { isSubmitting } = formState;
  useEffect(() => {
    ProjectActions.ProjectGetLookUp(langId, 20)
      .then((res) => {
        if (res.content) {
          setOptionProject(res.content.items);
        }
      })
      .catch((err) => console.log(err));
    MasterActions.GetMasterDataFromLangId({ langId })
      .then((res) => {
        if (res.realEstateTypeCache) {
          setOptionRealEstate(res.realEstateTypeCache);
        }
        if (res.directionCache) {
          setOptionDirection(res.directionCache);
        }
      })
      .catch((err) => console.log(err));

    ClassifiedActions.ClassifiedDetail(classId)
      .then((res) => {
        if (res.content) {
          setClassDetail(res.content);
          setListUUIDHistoryPrice(res.content?.landPriceHistories || []);
          setListUUIDHistoryTax(res.content?.landTaxHistories|| []);
          setLandPriceMonthy(res.content?.landPriceMonthy|| []);
          setActive(res.content?.isActive);
          setShowUi(res.content?.isShowUI);
          setValue("price", convertNumberToCurrency(res.content.price));
          setValue(
            "pricePerMettre",
            convertNumberToCurrency(res.content.pricePerMettre)
          );
          if (res.content.imageFile) {
            setFiles([res.content.imageFile]);
          }

          setArrayOfLib(res.content.galaryImageFile);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const onChangeDirection = (e, values) => {
    setDirection(values);
  };

  const onChangeEstate = (e, values) => {
    setEstate(values);
  };

  const onChangeProject = (e, values) => {
    setProject(values);
  };
  const handleChangeActive = (e) => {
    setActive(e.target.checked);
  };

  const handleChangeShowUi = (e) => {
    setShowUi(e.target.checked);
  };
  const handleChangeSold = (e) => {
    setSoldOut(e.target.checked);
  };

  const handleChangeExpire = (e) => {
    console.log(e.target.value);
    //  setExpire(e);
  };
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("id", classId);
    formData.append("title", data.title);
    formData.append("slug", data.slug);
    formData.append("description", content);
    (data.project || classDetail.projectId) &&
      formData.append(
        "projectId",
        data.project ? data.project.value : classDetail.projectId
      );
    ((direction && direction?.id) || classDetail.directionId) &&
      formData.append(
        "directionId",
        direction?.id
          ? parseInt(direction.id)
          : classDetail.directionId
          ? parseInt(classDetail.directionId)
          : null
      );
    formData.append(
      "realEstateTypeId",
      estate.id ? estate.id : classDetail.realEstateTypeId
    );
    formData.append(
      "addressId",
      data.address ? data.address.value : classDetail.addressId
    );
    //if (data.planning || classDetail.planningId)
    formData.append(
      "planningId",
      data.planning ? data.planning.value : classDetail.planningId
    );
    formData.append("telephoneNumber", data.telephoneNumber);
    formData.append("pricePerMettre", data.pricePerMettre);
    formData.append("priceFrom", data.price);
    formData.append("priceTo", data.price);
    formData.append("price", data.price);
    formData.append("atv", data.atv);
    formData.append("ath", data.ath);
    landPriceMonthy.interest && formData.append("landPriceMonthy.interest", landPriceMonthy.interest);
    landPriceMonthy.loanInsurance &&  formData.append("landPriceMonthy.loanInsurance", landPriceMonthy.loanInsurance);
    landPriceMonthy.taxPrice && formData.append("landPriceMonthy.taxPrice", landPriceMonthy.taxPrice);
    landPriceMonthy.landInsurance && formData.append("landPriceMonthy.landInsurance", landPriceMonthy.landInsurance);
    landPriceMonthy.fee && formData.append("landPriceMonthy.fee", landPriceMonthy.fee);
   
    formData.append("acreage", parseInt(data.acreage));
    data.floorCount && formData.append("floorCount", parseInt(data.floorCount));
    data.bedroomCount &&
      formData.append("bedroomCount", parseInt(data.bedroomCount));
    data.bathroomCount &&
      formData.append("bathroomCount", parseInt(data.bathroomCount));
    formData.append("isActive", isActive);
    formData.append("isShowUI", isShowUi);
    formData.append("isSoldOut", isSoldOut);
    formData.append("expiredDate", expire);
    formData.append("linkYoutube", data.linkYoutube);
    files && files.length > 0 && formData.append("imageId", files[0].fileId);
    if (arrayOfLib && arrayOfLib.length > 0) {
      arrayOfLib.map((item) => formData.append("galaryImage", item.fileId));
    }
    fileUpload &&
      fileUpload.length > 0 &&
      formData.append("image", fileUpload[0]);
    fileUploadGalary &&
      fileUploadGalary.length > 0 &&
      fileUploadGalary.map((item) => formData.append("galaryImages", item));
    listUUIDHistoryPrice && listUUIDHistoryPrice.length > 0 && listUUIDHistoryPrice.forEach((item, index) => {
      formData.append(`landPriceHistories[${index}].dateApply`, item?.dateApply);
        formData.append(`landPriceHistories[${index}].event`, item?.event);
        formData.append(`landPriceHistories[${index}].price`, item?.price);
        formData.append(`landPriceHistories[${index}].costPerM2`, item?.costPerM2);
        formData.append(`landPriceHistories[${index}].changeRate`, item?.changeRate);})
    listUUIDHistoryTax && listUUIDHistoryTax.length > 0 && listUUIDHistoryTax.forEach((item, index) => {
        formData.append(`landTaxHistories[${index}].dateApply`, item?.dateApply);
        formData.append(`landTaxHistories[${index}].tax`, item?.tax);
        formData.append(`landTaxHistories[${index}].changeTaxRate`, item?.changeTaxRate);
        formData.append(`landTaxHistories[${index}].taxPrice`, item?.taxPrice);
        formData.append(`landTaxHistories[${index}].changeTaxPrice`, item?.changeTaxPrice);})

    await ClassifiedActions.ClassifiedUpdateWithUpload(formData)
      .then((res) => {
        //setOrder('desc');
        //setOrderBy('modifiedDate');
        // GetListAll(
        //     undefined,
        //     langId,
        //     undefined,
        //     page+1,
        //     rowsPerPage,
        //     false,
        //     undefined,
        //     undefined,
        //     undefined,
        //     undefined
        // );
        ShowNotification(
          viVN.Success.ClassifiedEditSuccess,
          NotificationMessageType.Success
        );
      })
      .catch(
        (err) => viVN.Errors[err.errorType],
        NotificationMessageType.Error
      );
  };
  //const onSubmit = (data) => {
  //  let object = {
  //    id: singleClass.id,
  //    title: data.title,
  //    description: content,
  //    projectId: parseInt(
  //      data.project ? data.project.value : classDetail.projectId
  //    ),
  //    directionId: direction?.id ? parseInt(direction.id) : (classDetail.directionId ? parseInt(classDetail.directionId) : null),
  //    realEstateTypeId: parseInt(
  //      estate.id ? estate.id : classDetail.realEstateTypeId
  //    ),
  //    addressId: data.address ? data.address.value : singleClass.addressId,
  //    telephoneNumber: data.telephoneNumber,

  //    pricePerMettre: data.pricePerMettre,
  //    priceFrom: data.price,
  //    priceTo: data.price,
  //    price: data.price,
  //    acreage: parseInt(data.acreage),
  //    floorCount: parseInt(data.floorCount),
  //    bedroomCount: parseInt(data.bedroomCount),
  //    bathroomCount: parseInt(data.bathroomCount),

  //    isActive: isActive,
  //    isShowUI: isShowUi,
  //    isSoldOut: isSoldOut,
  //    expiredDate: expire,
  //    linkYoutube: data.linkYoutube,
  //  };

  //  if (files && files.length > 0) {
  //    object = {
  //      ...object,
  //      imageId: files[0].fileId,
  //    };
  //  }

  //  if (arrayOfLib && arrayOfLib.length > 0) {
  //    object = {
  //      ...object,
  //      galaryImage: arrayOfLib.map((item) => item.fileId),
  //    };
  //  }

  //  ClassifiedActions.ClassifiedUpdate(object)
  //    .then((res) => {
  //      setOrder('desc');
  //      setOrderBy('modifiedDate');
  //      GetListAll(
  //        undefined,
  //        langId,
  //        'modifiedDate desc',
  //        1,
  //        rowsPerPage,
  //        false,
  //        undefined,
  //        undefined,
  //        undefined
  //      );
  //      onSuccess();
  //      ShowNotification(
  //        viVN.Success.NewsAddSuccess,
  //        NotificationMessageType.Success
  //      );
  //    })
  //    .catch(
  //      (err) => viVN.Errors[err.errorType],
  //      NotificationMessageType.Error
  //    );
  //};
  const onChangeAddRowHistoryPrice = () => {
    const item = {
      id: uuidTmp(),
      dateApply: null,
      event: "",
      price: 0,
      changeRate: 0,
      costPerM2: 0,
    };
    const newData = [...listUUIDHistoryPrice];
    newData.push(item);
    setListUUIDHistoryPrice(newData);
  };
  const onChangeRemoveRowHistoryPrice = (id) => {
    const newData = [...listUUIDHistoryPrice];
    const newDataTmp = newData.filter((item) => item.id !== id);
    setListUUIDHistoryPrice(newDataTmp);
  };
  const onChangeRowHistoryPrice = (id, name, value) => {
    const itemTmp = listUUIDHistoryPrice.find((item) => item.id === id);
    const newItem = {
      ...itemTmp,
      [name]: value,
    };
    const newDataTmp = listUUIDHistoryPrice.filter((item) => item.id !== id);
    newDataTmp.push(newItem);
    setListUUIDHistoryPrice(newDataTmp);
  };
  const onChangeAddRowHistoryTax = () => {
    const item = {
      id: uuidTmp(),
      dateApply: null,
      tax: 0,
      changeTaxRate: 0,
      taxPrice: 0,
      changeTaxPrice: 0,
    };
    const newData = [...listUUIDHistoryTax];
    newData.push(item);
    setListUUIDHistoryTax(newData);
  };
  const onChangeRemoveRowHistoryTax = (id) => {
    const newData = [...listUUIDHistoryTax];
    const newDataTmp = newData.filter((item) => item.id !== id);
    setListUUIDHistoryTax(newDataTmp);
  };
  const onChangeRowHistoryTax = (id, name, value) => {
    const itemTmp = listUUIDHistoryTax.find((item) => item.id === id);
    const newItem = {
      ...itemTmp,
      [name]: value,
    };
    const newDataTmp = listUUIDHistoryTax.filter((item) => item.id !== id);
    newDataTmp.push(newItem);
    setListUUIDHistoryTax(newDataTmp);
  };
  const onChangePriceMonth = (name, value) => {
    setLandPriceMonthy({
      ...landPriceMonthy,
      [name]: value,
    });
  };
  const onOpenSelectFileLib = () => {
    setShowFileLib(true);
  };
  const onOpenSelectFile = () => {
    setShow(true);
  };

  const onCloseSelectFileLib = () => {
    setShowFileLib(false);
    setFileLib(setFileTempLib);
  };

  const onSaveSelectFileLib = () => {
    console.log(fileLib);
    if (arrayOfLib && arrayOfLib.length > 0)
      setArrayOfLib([...arrayOfLib, ...fileLib]);
    else setArrayOfLib([...fileLib]);
    setShowFileLib(false);
  };

  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
  };

  const onSaveSelectFile = () => {
    console.log(files);
    setShow(false);
  };

  const deleteImageSingle = (e, fileId) => {
    const arr = arrayOfLib.filter((item) => item?.fileId !== fileId);
    setArrayOfLib(arr);
  };

  const handleChangeContent = (editorContent) => {
    clearErrors(["editorContent"]);
    if (editorContent === "<p><br></p>") {
      setError("editorContent", { type: "required" });
      setContent("");
    } else {
      clearErrors("editorContent");
      setContent(editorContent);
    }
  };
  const onGoBack = () => {
    const queryParams = window.location.search;
    history.push(UrlCollection.Classified + queryParams);
  };
  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div
            className="d-sm-flex align-items-center mb-2"
            onClick={onGoBack}
            style={{ cursor: "pointer", width: 100 }}
          >
            <ArrowBackIos fontSize="small"></ArrowBackIos> Quay lại
          </div>
          <div className="d-sm-flex align-items-center justify-content-between mb-3">
            <h1 className="h3 mb-0 text-gray-800">Sửa tin mua bán</h1>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={
                !isSubmitting ? (
                  <SaveIcon></SaveIcon>
                ) : (
                  <CircularProgress size={20} color="white" />
                )
              }
              disabled={isSubmitting}
            >
              Lưu
            </Button>
          </div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={`${tab === 1 ? "active" : ""}`}
                onClick={() => setTab(1)}
              >
                Thông tin chung
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${tab === 2 ? "active" : ""}`}
                onClick={() => setTab(2)}
              >
                Lịch sử giá
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${tab === 3 ? "active" : ""}`}
                onClick={() => setTab(3)}
              >
                Lịch sử thuế
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${tab === 4 ? "active" : ""}`}
                onClick={() => setTab(4)}
              >
                Giá theo tháng
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={tab}>
          <TabPane tabId={1}>
            {classDetail && (
              <DialogContent className="pt-4 pb-2">
                <div className="form-group row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <label className="text-dark">
                      Tiêu đề<span className="required"></span>
                    </label>
                    <TextField
                      name="title"
                      error={
                        errors.title &&
                        (errors.title.type === "required" ||
                          errors.title.type === "maxLength")
                      }
                      fullWidth
                      type="text"
                      className="form-control"
                      defaultValue={classDetail.title}
                      inputRef={register({ required: true, maxLength: 150 })}
                      inputProps={{ maxLength: 150 }}
                      onChange={(e) => setValue("title", e.target.value)}
                    />
                    {errors.title && errors.title.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                    {errors.title && errors.title.type === "maxLength" && (
                      <span className="error">
                        Trường này không quá 150 ký tự
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <label className="text-dark">Liên kết</label>
                    <TextField
                      name="slug"
                      fullWidth
                      type="text"
                      className="form-control"
                      defaultValue={classDetail.slug}
                      inputRef={register({ required: false })}
                      onChange={(e) => setValue("slug", e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12 col-md-3 col-lg-3">
                    <label className="text-dark">Số điện thoại:</label>
                    <TextField
                      name="telephoneNumber"
                      error={
                        errors.telephoneNumber &&
                        errors.telephoneNumber.type === "maxLength"
                      }
                      fullWidth
                      type="number"
                      className="form-control"
                      defaultValue={classDetail.telephoneNumber}
                      inputProps={{ maxLength: 12 }}
                      inputRef={register({
                        minLength: 6,
                        maxLength: 12,
                      })}
                      onChange={(e) =>
                        setValue("telephoneNumber", e.target.value)
                      }
                    />
                    {errors.phoneNumber &&
                      errors.phoneNumber.type === "maxLength" && (
                        <span className="error">
                          Trường này không quá 12 số
                        </span>
                      )}
                  </div>
                  <div className="col-12 col-md-3 col-lg-3">
                    <label className="text-dark">
                      Diện tích:<span className="required"></span>
                    </label>
                    <TextField
                      name="acreage"
                      error={
                        errors.acreage &&
                        (errors.acreage.type === "required" ||
                          errors.acreage.type === "maxLength")
                      }
                      fullWidth
                      type="number"
                      className="form-control"
                      defaultValue={classDetail.acreage}
                      inputRef={register({ required: true, maxLength: 150 })}
                      inputProps={{ maxLength: 150 }}
                      onChange={(e) => setValue("acreage", e.target.value)}
                    />
                    {errors.acreage && errors.acreage.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  </div>
                  <div className="col-12 col-md-3 col-lg-3">
                    <label className="text-dark">Số lượng phòng ngủ:</label>
                    <TextField
                      name="bedroomCount"
                      // error={
                      //   errors.bedroomCount &&
                      //   (errors.bedroomCount.type === 'required' ||
                      //     errors.bedroomCount.type === 'maxLength')
                      // }
                      fullWidth
                      type="number"
                      className="form-control"
                      inputRef={register({ maxLength: 150 })}
                      defaultValue={classDetail.bedroomCount}
                      // inputProps={{ maxLength: 150 }}
                      onChange={(e) => setValue("bedroomCount", e.target.value)}
                    />
                    {/* {errors.bedroomCount &&
                  errors.bedroomCount.type === 'required' && (
                    <span className='error'>Trường này là bắt buộc</span>
                  )} */}
                  </div>
                  <div className="col-12 col-md-3 col-lg-3">
                    <label className="text-dark">Số lượng nhà WC:</label>
                    <TextField
                      name="bathroomCount"
                      // error={
                      //   errors.bathroomCount &&
                      //   (errors.bathroomCount.type === 'required' ||
                      //     errors.bathroomCount.type === 'maxLength')
                      // }
                      fullWidth
                      type="text"
                      className="form-control"
                      defaultValue={classDetail.bathroomCount}
                      inputRef={register({ maxLength: 150 })}
                      inputProps={{ maxLength: 150 }}
                      onChange={(e) =>
                        setValue("bathroomCount", e.target.value)
                      }
                    />
                    {/* {errors.bathroomCount &&
                  errors.bathroomCount.type === 'required' && (
                    <span className='error'>Trường này là bắt buộc</span>
                  )} */}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-12 col-md-3 col-lg-3">
                    <FormInputCurrency
                      title="Giá bán"
                      name="price"
                      placeholder="Giá bán"
                      validate={{
                        required: "Giá bán là dữ liệu bắt buộc",
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-3 col-lg-3">
                    <FormInputCurrency
                      title="Giá/m2"
                      name="pricePerMettre"
                      placeholder="Giá/m2"
                      validate={{
                        required: "Giá/m2 là dữ liệu bắt buộc",
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-3 col-lg-3">
                    <label className="text-dark">Số tầng:</label>
                    <TextField
                      name="floorCount"
                      // error={
                      //   errors.floorCount &&
                      //   (errors.floorCount.type === 'required' ||
                      //     errors.floorCount.type === 'maxLength')
                      // }
                      fullWidth
                      type="number"
                      className="form-control"
                      inputRef={register({ maxLength: 150 })}
                      defaultValue={classDetail.floorCount}
                      // inputProps={{ maxLength: 150 }}
                      onChange={(e) => setValue("floorCount", e.target.value)}
                    />
                    {/* {errors.floorCount && errors.floorCount.type === 'required' && (
                  <span className='error'>Trường này là bắt buộc</span>
                )} */}
                  </div>
                  <div className="col-12 col-md-3 col-lg-3">
                    <label className="text-dark">Ngày hết hạn</label>
                    <TextField
                      type="date"
                      defaultValue={formatDateToSubmit(classDetail.expiredDate)}
                      onChange={handleChangeExpire}
                      color="primary"
                      className="form-control p-0 mt-0"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  {optionRealEstate.length > 0 && (
                    <div className="col-12 col-md-4 col-lg-4">
                      <Autocomplete
                        id="combo-box-demo"
                        options={optionRealEstate}
                        getOptionLabel={(option) => option.name}
                        onChange={onChangeEstate}
                        style={{ width: "100%" }}
                        defaultValue={{
                          id: classDetail.realEstateTypeId,
                          name: classDetail.realEstateTypeName,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Loại hình BĐS"
                            variant="outlined"
                            name="realEstateTypeId"
                            inputRef={register({ required: true })}
                            error={
                              errors.realEstateTypeId &&
                              errors.realEstateTypeId.type === "required"
                            }
                            size="small"
                          />
                        )}
                      />
                      {errors.realEstateTypeId &&
                        errors.realEstateTypeId.type === "required" && (
                          <span className="error">Trường này là bắt buộc</span>
                        )}
                    </div>
                  )}
                  <div className="col-12 col-md-4 col-lg-4">
                    <FormAsyncSelecPagination
                      name="project"
                      placeholder="Dự án"
                      defaultValue={{
                        value: classDetail?.project?.projectId,
                        label: classDetail?.project?.projectName,
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    {optionDirection.length > 0 && (
                      <Autocomplete
                        id="combo-box-demo"
                        name="direction"
                        options={optionDirection}
                        getOptionLabel={(option) => option.name}
                        onChange={onChangeDirection}
                        style={{ width: "100%" }}
                        defaultValue={{
                          id: classDetail?.directionId,
                          name: classDetail?.directionName,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Hướng"
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12 col-md-12 col-lg-12">
                    {langId && (
                      <FormInputAddress
                        name="address"
                        placeholder="Địa chỉ"
                        langId={langId}

                        defaultValue={{
                          value: classDetail.addressId,
                          label: classDetail.addressDetail,
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <label className="text-dark">Ath: </label>
                    <TextField
                      name="ath"
                      fullWidth
                      type="number"
                      defaultValue={classDetail?.ath}
                      className="form-control"
                      inputRef={register({ maxLength: 150 })}
                      // inputProps={{ maxLength: 150 }}
                      onChange={(e) => setValue("ath", e.target.value)}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <label className="text-dark">Atv:</label>
                    <TextField
                      name="atv"
                      fullWidth
                      type="number"
                      defaultValue={classDetail?.atv}
                      className="form-control"
                      inputRef={register({ maxLength: 150 })}
                      inputProps={{ maxLength: 150 }}
                      onChange={(e) => setValue("atv", e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <FormAsyncPlanning
                      name="planning"
                      placeholder="Chọn quy hoạch..."
                      defaultValue={{
                        value: classDetail?.planningId,
                        label: classDetail?.planningName,
                      }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div class="row">
                    <div className="col-12 col-md-2 col-lg-2">
                      <label className="text-dark">Hoạt động</label>
                      <Checkbox
                        checked={isActive}
                        onChange={handleChangeActive}
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        className="p-0 mt-0 ml-4"
                      />
                    </div>
                    <div className="col-12 col-md-3 col-lg-3">
                      <label className="text-dark">Hiển thị ở trang web</label>
                      <Checkbox
                        checked={isShowUi}
                        onChange={handleChangeShowUi}
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        className="p-0 mt-0 ml-4"
                      />
                    </div>
                    <div className="col-12 col-md-2 col-lg-2">
                      <label className="text-dark">Đã bán</label>
                      <Checkbox
                        checked={isSoldOut}
                        onChange={handleChangeSold}
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        className="p-0 mt-0 ml-4"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="text-dark">Mô tả</label>
                  <SunEditor
                    enableToolbar={true}
                    showToolbar={true}
                    setContents={classDetail.description}
                    imageUploadSizeLimit={MaxSizeImageUpload}
                    videoFileInput={false}
                    setOptions={{
                      height: 500,
                      imageUploadUrl: urlUploadImage,
                      buttonList: [
                        [
                          "undo",
                          "redo",
                          "font",
                          "fontSize",
                          "formatBlock",
                          "paragraphStyle",
                          "blockquote",
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "subscript",
                          "superscript",
                          "fontColor",
                          "hiliteColor",
                          "textStyle",
                          "removeFormat",
                          "outdent",
                          "indent",
                          "align",
                          "horizontalRule",
                          "list",
                          "lineHeight",
                          "table",
                          "link",
                          "image",
                          "video",
                          "audio",
                          "fullScreen",
                          "showBlocks",
                          "codeView",
                        ],
                      ],
                    }}
                    onChange={handleChangeContent}
                    onBlur={(event, editorContents) =>
                      handleChangeContent(editorContents)
                    }
                  />
                </div>

                <div className="form-group row">
                  <div className="col-12">
                    <label className="text-dark">Thêm video từ Youtube</label>
                    <TextField
                      name={"linkYoutube"}
                      placeholder={
                        "https://www.youtube.com/watch?v=uEP92B9zB5c"
                      }
                      error={
                        errors.linkYoutube &&
                        errors.linkYoutube.type === "pattern"
                      }
                      defaultValue={classDetail?.linkYoutube}
                      fullWidth
                      type="text"
                      className="form-control"
                      // inputRef={register({
                      //   pattern:
                      //     /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                      // })}
                    />
                    {errors.linkYoutube &&
                      errors.linkYoutube.type === "pattern" && (
                        <span className="error">
                          Trường này chưa phải là URL
                        </span>
                      )}
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-4">
                    <label className="text-dark">Ảnh đại diện</label>
                    {files &&
                      files.length > 0 &&
                      files.map((item) => (
                        <div key={item.fileName} style={{ width: "150px" }}>
                          <img
                            src={APIUrlDefault + item.filePreview}
                            alt={item.fileName}
                            className="img-fluid mb-2"
                            style={{
                              width: "auto",
                              height: "auto",
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          />
                        </div>
                      ))}
                    <div>
                      {
                        //                        <Button
                        //                            variant='contained'
                        //                            color='primary'
                        //                            onClick={onOpenSelectFile}>
                        //                            Chọn file
                        //</Button>
                      }
                      <TextField
                        inputRef={register({ required: false })}
                        type="hidden"
                        name="image"
                        value={
                          (files && files.length > 0 && files[0].fileName) || ""
                        }
                      />
                      {errors.image && errors.image.type === "required" && (
                        <p className="error">Trường này là bắt buộc</p>
                      )}
                    </div>
                  </div>
                  <div className="col-8">
                    <DropzoneArea
                      acceptedFiles={[
                        "image/png, image/gif, image/jpeg, image/jpg",
                      ]}
                      filesLimit={1}
                      dropzoneText="Upload ảnh đại diện"
                      onChange={(files) => {
                        console.log("Files:", files);
                        setFileUpload(files);
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-12">
                    <label className="text-dark">Thư viện ảnh:</label>
                    <div className="row">
                      {arrayOfLib &&
                        arrayOfLib.length > 0 &&
                        arrayOfLib.map((item) => (
                          <div className="col-12 col-md-6 col-lg-2">
                            <div
                              key={item.fileName}
                              style={{ width: "150px", position: "relative" }}
                            >
                              <div
                                className={classes.closeButtonImage}
                                onClick={(e) =>
                                  deleteImageSingle(e, item.fileId)
                                }
                              >
                                <CloseIcon />
                              </div>
                              <img
                                src={APIUrlDefault + item.filePreview}
                                alt={item.fileName}
                                className="img-fluid mb-2"
                                style={{
                                  width: "auto",
                                  height: "auto",
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                }}
                              />
                            </div>
                          </div>
                        ))}
                    </div>

                    {
                      //                    <div>
                      //<Button
                      //  variant='contained'
                      //  color='primary'
                      //  onClick={onOpenSelectFileLib}>
                      //  Chọn file
                      //</Button>
                      //<TextField
                      //  //inputRef={register({ required: true })}
                      //  type='hidden'
                      //  name='imageLib'
                      //  value={
                      //    (fileLib &&
                      //      fileLib.length > 0 &&
                      //      fileLib[0].fileName) ||
                      //    ''
                      //  }
                      ///>
                      /* {errors.image && errors.image.type === 'required' && (
                    <p className='error'>Trường này là bắt buộc</p>
                  )} */
                      //</div>
                    }
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <DropzoneArea
                      acceptedFiles={[
                        "image/png, image/gif, image/jpeg, image/jpg",
                      ]}
                      filesLimit={20}
                      dropzoneText="Chọn và upload ảnh thư viện"
                      onChange={(files) => {
                        console.log("Files:", files);
                        setFileUploadGalary(files);
                      }}
                    />
                  </div>
                </div>
              </DialogContent>
            )}
          </TabPane>
          <TabPane tabId={2}>
            <div className="mt-4">
              {listUUIDHistoryPrice.length > 0 &&
                listUUIDHistoryPrice.map((item) => (
                  <HistoryPriceView
                    item={item}
                    onChangeRowHistoryPrice={onChangeRowHistoryPrice}
                    onChangeRemoveRowHistoryPrice={
                      onChangeRemoveRowHistoryPrice
                    }
                  ></HistoryPriceView>
                ))}
              {listUUIDHistoryPrice.length === 0 && (
                <div style={{ textAlign: "center" }}>Chưa có row item nào!</div>
              )}
            </div>
            <hr></hr>
            <div className="align-items-center" style={{ textAlign: "end" }}>
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={onChangeAddRowHistoryPrice}
              >
                Thêm row lịch sử giá
              </Button>
            </div>
          </TabPane>
          <TabPane tabId={3}>
            <div className="mt-4">
              {listUUIDHistoryTax.length > 0 &&
                listUUIDHistoryTax.map((item) => (
                  <HistoryTaxView
                    item={item}
                    onChangeRowHistoryTax={onChangeRowHistoryTax}
                    onChangeRemoveRowHistoryTax={onChangeRemoveRowHistoryTax}
                  ></HistoryTaxView>
                ))}
              {listUUIDHistoryTax.length === 0 && (
                <div style={{ textAlign: "center" }}>Chưa có row item nào!</div>
              )}
            </div>
            <hr></hr>
            <div className="align-items-center" style={{ textAlign: "end" }}>
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={onChangeAddRowHistoryTax}
              >
                Thêm row lịch sử giá
              </Button>
            </div>
          </TabPane>
          <TabPane tabId={4}>
            <div className="mt-4">
              <PriceMonth
                landPriceMonthy={landPriceMonthy}
                onChangePriceMonth={onChangePriceMonth}
              ></PriceMonth>
            </div>
          </TabPane>
          </TabContent>
         
        </form>
      </FormProvider>

      {isShow && (
        <Dialog
          onClose={onCloseSelectFile}
          open={isShow}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={files}
              setFiles={setFiles}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {files && files.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {isShowFileLib && (
        <Dialog
          onClose={onCloseSelectFileLib}
          open={isShowFileLib}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFileLib}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={fileLib}
              setFiles={setFileLib}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFileLib}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {fileLib && fileLib.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFileLib}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
