import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Configs } from "../../common/config";
import { useForm } from "react-hook-form";

//--- Material Control
import {
  Button,
  TextField,
  IconButton,
  Tooltip,
  Popover,
  Select,
  MenuItem,
  Fab,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ButtonGroup from "@material-ui/core/ButtonGroup";

//--- Styles
import "./faq.scss";

//--- Material Icon
import DeleteCircle from "@material-ui/icons/DeleteForever";
import AddCircle from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
//import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import RefreshIcon from "@material-ui/icons/Refresh";

//--- Component
import ListFaqs from "./list-faq/list-faq.view";
import AddFaqs from "./add-faq/add-faq.view";
import EditFaqs from "./edit-faq/edit-faq.view";
import AddAnsFaqs from "./add-ans/add-ans.view";
import EditAnsFaqs from "./edit-ans/edit-ans.view";
import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";

//--- Notifications
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";
import * as viVN from "../../language/vi-VN.json";

//--- Redux store
import * as faqActions from "../../redux/store/faq/faq.store";
import { makeStyles } from "@material-ui/core/styles";
import * as appActions from "../../core/app.store";
// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));

export default function Faq(props) {
  //  const classes = useStyles();
  const dispatch = useDispatch();
  const [newsModels, setNewsModels] = useState();
  const [singleQues, setSingleQues] = useState({});
  const { langId } = useSelector((state) => state.language);
  const [totalItemCount, setTotalItemCount] = useState();
  const [searchData, setSearchData] = useState({
    //  categoryId: 1,
    keyword: "",
    // description: '',
    // status: 2,
  });
  const [faqId, setFaqId] = useState();
  const [newsxmin, setNewsxmin] = useState();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("modifiedDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(Configs.DefaultPageSize);

  const [isDelete, setIsDelete] = useState(false);
  const [isShowTrash, setIsShowTrash] = useState(false);

  const { register, handleSubmit } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    GetListAll(
      undefined,
      langId,
      orderBy + " " + order,
      page + 1,
      rowsPerPage,
      isDelete
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetListAll = (
    keyword,
    langId,
    sortExpression,
    pageIndex,
    pageSize,
    isDelete
  ) => {
    dispatch(appActions.ShowLoading(true))
    setPage(pageIndex - 1);
    faqActions
      .GetListAll(
        keyword,
        langId,
        sortExpression,
        pageIndex,
        pageSize,
        isDelete
      )
      .then((res) => {
        if (res && res.content) {
          dispatch(appActions.ShowLoading(false))
          setNewsModels(res.content.items);
          setTotalItemCount(res.content.totalItemCount);
        }
      })
      .catch((err) => console.log(err));
  };

  //--- Dialog
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openAddAnsDialog, setOpenAddAnsDialog] = useState(false);
  const [openEditAnsDialog, setOpenEditAnsDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleOpenAddAnsDialog = (ques) => {
    setOpenAddAnsDialog(true);
    setSingleQues(ques);
  };

  const handleCloseAddAnsDialog = () => {
    setOpenAddAnsDialog(false);
  };

  const handleOpenEditAnsDialog = (ques) => {
    setOpenEditAnsDialog(true);
    setSingleQues(ques);
  };

  const handleCloseEditAnsDialog = () => {
    setOpenEditAnsDialog(false);
  };

  const handleOpenEditDialog = (ques) => {
    setFaqId(faqId);
    setSingleQues(ques);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleOpenDeleteDialog = (faqId, newsxmin) => {
    setFaqId(faqId);
    setNewsxmin(newsxmin);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  //--- Filter
  const [filterSection, setFilterSection] = useState(null);

  const handleClickFilter = (event) => {
    setFilterSection(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterSection(null);
  };

  const ShowNotificationError = (messages) => {
    ShowNotification(messages, NotificationMessageType.Error);
  };

  const openFilter = Boolean(filterSection);
  const idFilter = openFilter ? "popoverSlider" : undefined;

  const handleRestore = (id) => {
    // faqActions.RestoreNews(id).then(
    //   (res) => {
    //     if (res && res.content && res.content.status) {
    //       GetListAll(
    //         searchData.categoryId,
    //         searchData.title,
    //         undefined,
    //         undefined,
    //         isDelete,
    //         searchData.status,
    //         1,
    //         rowsPerPage,
    //         orderBy + ' ' + order
    //       );
    //       ShowNotification(
    //         viVN.Success.NewsRestoreSuccess,
    //         NotificationMessageType.Success
    //       );
    //     }
    //   },
    //   (err) => {
    //     err &&
    //       err.errorType &&
    //       ShowNotification(
    //         viVN.Errors[err.errorType],
    //         NotificationMessageType.Error
    //       );
    //   }
    // );
  };
  const handleDelete = () => {
    faqActions.FaqDelete(faqId).then(
      (res) => {
        if (res && res.content && res.content.status) {
          GetListAll(
            searchData.keyword,
            langId,
            orderBy + " " + order,
            page + 1,
            rowsPerPage,
            isDelete
          );
          handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.NewsDeleteSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };

  const handelChangeFeature = (newsId, value) => {
    alert("change " + newsId + " feature to" + value);
  };
  const handleClearAllField = () => {
    refresh();
    setFilterSection(null);
  };

  const onSubmit = async (data) => {
    await GetListAll(
      searchData.keyword,
      langId,
      orderBy + " " + order,
      page + 1,
      rowsPerPage,
      isDelete
    );
    handleCloseFilter();
  };

  const refresh = () => {
    setIsDelete(false);
    setIsShowTrash(false);
    setSearchData({
      keyword: "",
    });
    setOrderBy("modifiedDate");
    setOrder("desc");
    GetListAll(
      searchData.keyword,
      langId,
      orderBy + " " + order,
      page + 1,
      rowsPerPage,
      isDelete
    );
  };

  const handleChangeTrash = (event) => {
    setIsShowTrash(event.target.checked);
    setIsDelete(event.target.checked);
    GetListAll(
      searchData.keyword,
      langId,
      orderBy + " " + order,
      page + 1,
      rowsPerPage,
      event.target.checked
    );
  };

  const handleChangeSearchForm = (event) => {
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  const handleEmptyTrash = () => {
    faqActions.EmptyTrash().then(
      (res) => {
        if (res && res.content && res.content.status) {
          GetListAll(
            searchData.keyword,
            langId,
            orderBy + " " + order,
            page + 1,
            rowsPerPage,
            isDelete
          );
          //handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.NewsDeleteSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };

  return (
    <div className="slider">
      <div className="d-sm-flex align-items-center justify-content-between mb-3">
        <h1 className="h3 mb-0 text-gray-800">
          FAQ
          <Tooltip title="Làm mới">
            <Fab
              color="primary"
              aria-label="filter"
              size="small"
              onClick={refresh}
              className="ml-2"
            >
              <RefreshIcon />
            </Fab>
          </Tooltip>
          <span>&nbsp;&nbsp;</span>
          <FormControlLabel
            control={
              <Switch
                checked={isShowTrash}
                onChange={handleChangeTrash}
                name="checkedB"
                color="primary"
              />
            }
            label="Thùng rác"
          />
        </h1>
        <ButtonGroup
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          {isShowTrash && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleEmptyTrash}
              startIcon={<DeleteCircle />}
            >
              Xóa thùng rác
            </Button>
          )}
         
        </ButtonGroup>
        <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAddDialog}
            startIcon={<AddCircle />}
          >
            Thêm FAQ
          </Button>
      </div>
      <div className="d-flex justify-content-between align-items-end">
        <form id="formSearch" onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex align-items-end">
            <div className="form-group">
              <TextField
                className="w-100"
                placeholder="Từ khoá"
                name="keyword"
                variant="outlined"
                value={searchData.keyword}
                onChange={handleChangeSearchForm}
                inputRef={register}
              />
            </div>
            <div className="form-group ml-2">
              <Button variant="contained" color="primary" onClick={onSubmit} className="ml-2">
                <SearchIcon fontSize="small" /> Tìm kiếm
              </Button>
              <Button
                variant="contained"
                className="ml-2"
                onClick={handleClearAllField}
              >
                <ClearAllIcon fontSize="small" /> Bỏ lọc
              </Button>
            </div>
          </div>
        </form>
        <div className="form-group">
       
        </div>
      </div>
      {newsModels && (
        <ListFaqs
          totalItemCount={totalItemCount}
          newsModels={newsModels}
          GetListAll={GetListAll}
          title={searchData.title}
          categoryId={searchData.categoryId}
          isDelete={isDelete}
          editAction={handleOpenEditDialog}
          deleteAction={handleOpenDeleteDialog}
          addAnsAction={handleOpenAddAnsDialog}
          editAnsAction={handleOpenEditAnsDialog}
          restoreAction={handleRestore}
          changeFeatureAction={handelChangeFeature}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          order={order}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
          langId={langId}
          keyword={searchData.keyword}
        />
      )}
      {openAddDialog && (
        <AddFaqs
          isOpen={openAddDialog}
          onClose={handleCloseAddDialog}
          onSuccess={handleCloseAddDialog}
          ShowNotificationError={ShowNotificationError}
          GetListAll={GetListAll}
          rowsPerPage={rowsPerPage}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          langId={langId}
        />
      )}

      {openAddAnsDialog && (
        <AddAnsFaqs
          isOpen={openAddAnsDialog}
          onClose={handleCloseAddAnsDialog}
          onSuccess={handleCloseAddAnsDialog}
          ShowNotificationError={ShowNotificationError}
          GetListAll={GetListAll}
          rowsPerPage={rowsPerPage}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          langId={langId}
          singleQues={singleQues}
        />
      )}

      {openEditAnsDialog && (
        <EditAnsFaqs
          isOpen={openEditAnsDialog}
          onClose={handleCloseEditAnsDialog}
          onSuccess={handleCloseEditAnsDialog}
          ShowNotificationError={ShowNotificationError}
          GetListAll={GetListAll}
          rowsPerPage={rowsPerPage}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          langId={langId}
          singleQues={singleQues}
        />
      )}

      {openDeleteDialog && (
        <DeleteDialog
          isOpen={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onSuccess={handleDelete}
          header={"Xác nhận xóa"}
          content={"Bạn có chắc chắn muốn xóa?"}
          langId={langId}
        />
      )}

      {openEditDialog && (
        <EditFaqs
          isOpen={openEditDialog}
          onClose={handleCloseEditDialog}
          onSuccess={handleCloseEditDialog}
          ShowNotificationError={ShowNotificationError}
          GetListAll={GetListAll}
          rowsPerPage={rowsPerPage}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          langId={langId}
          singleQues={singleQues}
        />
      )}
    </div>
  );
}
