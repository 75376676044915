// Build-in modules
import React, {useEffect, useState} from 'react';

// Internal Modules
import ProjectForm from '../project.form';
import * as projectAction from '../../../redux/store/project-management/project-store';
import { useSelector } from 'react-redux';

export default function EditProject(props) {
  const { langId } = useSelector((state) => state.language);
  const [currentProject, setCurrentProject] = useState(null);
  const { projectId } = props.match.params;
  useEffect(() => {
    async function getProjectDetails(_projectId) {
      try {
        const {content} = await projectAction.ProjectDetail(_projectId, langId);

        setCurrentProject(content);
      } catch (error) {
        setCurrentProject(null);
      }
    }

    if (projectId) {
      getProjectDetails(projectId);
    }
  }, []);

  return currentProject ? (
    <ProjectForm {...props} langId={langId} currentProject={currentProject} isEdit={true} />
  ) : null;
}
