export const Configs = {
  DefaultPageIndex: 1,
  DefaultPageSize: 20,
  DefaultPageSizeOption: [10, 20, 50],
  DefaultCapacityFile: 5000000,
  DefaultSortExpression: "modifiedDate desc",
  DefaultOrder: "desc",
  DefaultOrderBy: "modifiedDate",
  fileDocument: 100,
  imageExtention:["jpg","jpeg","png","gif"],
};

export const NotificationConfig = {
  emptyAttribute: "Không có",
  update: "Cập nhật",
  save: "Lưu",
};

export const EmailConfig = {
  emptyAttribute: "Không có",
  update: "Cập nhật",
  save: "Lưu",
};

export const regex = {
  specialCharacter: "/([^@!#$%^&*()*-])w+/",
};

export const UserSidePathUrl = {
    landAdvertising: "/land-advertising/",
    project: "/projects/",
    planningNews: "/planning-news/",
    projectNews: "/project-news/",
    lawDocument: "/records/van-ban-phap-ly-4/",
    flowInvestment: "/records/quy-trinh-dau-tu-du-an-3/",
    News: "/news/",
    map: "/map?id=",
};
