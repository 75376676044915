import Service from '../../../api/api-service';
import { ApiUrl } from '../../../api/api-url';
import * as config from '../../../common/config';

const service = new Service();

export const GetListUserAdminManagement = (
  pageIndex = 1,
  pageSize = config.Configs.DefaultPageSize,
  sortExpression = '',
  email = ''
) => {
  const params = new URLSearchParams();
  params.append('pageIndex', pageIndex);
  params.append('pageSize', pageSize);
  sortExpression && params.append('sortExpression', sortExpression);
  email && params.append('email', email);
  return service
    .get(ApiUrl.GetListUserAdminManagement, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetDetailUserAdminManagement = (id) => {
  const params = new URLSearchParams();
  params.append('id', id);
  return service
    .get(ApiUrl.GetDetailUserAdminManagement.trim(), params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const CreateUserAdminManagement = (body) => {
  return service
    .post(ApiUrl.CreateUserAdminManagement, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const UpdateUserAdminManagement = (body) => {
  return service
    .post(ApiUrl.UpdateUserAdminManagement, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const DeleteUserAdminManagement = (id) => {
  const params = new URLSearchParams();
  params.append('id', id);
  return service
    .postParams(ApiUrl.DeleteUserAdminManagement, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetRoleLookupUserAdminManagement = () => {
  return service
    .get(ApiUrl.GetRoleLookupUserAdminManagement)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const ActiveAdminUser = (id) => {
  const params = new URLSearchParams();
  params.append('id', id);
  return service
    .postParams(ApiUrl.ActiveAdminUser, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const DeActiveAdminUser = (id) => {
  const params = new URLSearchParams();
  params.append('id', id);
  return service
    .postParams(ApiUrl.DeActiveAdminUser, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const ResetPasswordUserAdminManagement = (id, password) => {
  const params = new URLSearchParams();
  params.append('userId', id);
  params.append('Password', password);
  return service
      .postParams(ApiUrl.ResetPasswordUserAdminManagement, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
