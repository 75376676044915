import React, { useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

//--- Styles
import "./project.scss";

//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Checkbox,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Grid } from "@material-ui/core";
//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { UrlCollection } from './../../common/url-collection';
import ShowNotification from "../../components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  APIUrlDefault,
  MaxSizeImageUpload,
} from "../../utils/configuration";
import * as viVN from "../../language/vi-VN.json";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import DateFnsUtils from "@date-io/date-fns";
import CircularProgress from '@material-ui/core/CircularProgress';
//--- Action
import * as projectAction from "../../redux/store/project-management/project-store";
import * as provinceAction from "../../redux/store/province-management/province.store";
import * as masterDataAction from "../../redux/store/master-data/master-data.store";
import * as investorAction from "../../redux/store/investor/investor.store";
import buildFormData from "../../utils/helpers/formDataBuild";

import UtilitiesList from "./components/utilities-list";
import CustomFormSelect from "./components/form-select";
import SelectProvince from "./components/select-province";
import SelectInvestor from "./components/select-investor";
import SelectProjectStatus from "./components/select-project-status";
import SelectProjectProgress from "./components/select-project-progress";
import SelectRealType from "./components/select-real-estate-type";
import { destructureFormData } from "./helpers";
import { useGetDefaultProject } from "./hooks/useGetDefaultProject";
import ProjectImage from "./components/project-image";
import ProjectImageTw from "./components/project-twitter";
import ProjectImageFb from "./components/project-facebook";
import ProjectContent from "./components/project-content";
import FormInputAddress from "../../components/FormItem/FormInputAddress";
import ProjectGallery from "./components/project-gallery";
import FileManagement from "../../components/file_management/file_management";
import SelectImageDialog from "./components/select-image-dialog";
import ProjectGroundImage from "./components/project-ground-image";
import ProjectActive from "./components/project-active";
import ProjectIsFeature from "./components/project-is-feature";
import { validateNumber, validatePhoneNumber } from "../../utils/helpers";
import ErrorMessage from "./components/error-message";
import ProjectInputCurrency from "./components/project-input-currency";
import FormAsynceSelectPaginationInvestor from "../../components/FormItem/FormAsynceSelecPaginationInvestor";
import FormAsyncPlanning from "../../components/FormItem/FormAsynceSelectPaginationPlanning";
import TabPanel from "../../components/tabpanel/tabpanel";
import a11yProps from "../../utils/helpers/a11yProps";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
  closeButtonImage: {
    position: "absolute",
    right: "2px",
    top: "3px",
    color: theme.palette.grey[500],
    opacity: "0.7",
    background: "white",
    borderRadius: "50%",
    cursor: "pointer",
  },
}));

const MAX_LENGTH = "maxLength";

const TRANSLATIONS = "translations";
const PR_LANG_ID = `${TRANSLATIONS}[0].langId`;
const PR_NAME = `${TRANSLATIONS}[0].name`;
const PR_DESCRIPTION = `${TRANSLATIONS}[0].description`;
const PR_META_TITLE = `${TRANSLATIONS}[0].metaTitle`;
const PR_META_KEYWORD = `${TRANSLATIONS}[0].metaKeyword`;
const PR_META_DESCRIPTION = `${TRANSLATIONS}[0].metaDescription`;
const PR_SHORT_DESCRIPTION = `${TRANSLATIONS}[0].shortDescription`;
const PR_FB_DESCRIPTION = `${TRANSLATIONS}[0].fbDescription`;
const PR_TW_DESCRIPTION = `${TRANSLATIONS}[0].twitterDescription`;
const PR_SLUG = `${TRANSLATIONS}[0].slug`;
const REQUIRED_NUMBER_MSG = "Vui lòng nhập số";
const REQUIRED_FIELD_MSG = "Trường này là bắt buộc";

const checkIsError = ({
  errors,
  name = "",
  type = "required",
  isArr = false,
}) => {
  let errorObj = null;
  let fieldName = name;

  if (!errors) {
    return false;
  }

  if (isArr) {
    const splitStr = name?.split("[0].");
    const arrName = splitStr && splitStr[0] ? splitStr[0] : "";
    const objName = splitStr && splitStr[1] ? splitStr[1] : "";
    fieldName = objName;
    errorObj =
      errors[arrName] && errors[arrName][0] ? errors[arrName][0] : null;
  } else {
    errorObj = errors;
  }

  if (errorObj && errorObj[fieldName] && errorObj[fieldName].type === type) {
    return true;
  }

  return false;
};

//function a11yProps(index) {
//    return {
//        id: `simple-tab-${index}`,
//        'aria-controls': `simple-tabpanel-${index}`,
//    };
//}

export default function ProjectForm(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    isEdit = false,
    langId,
    currentProject = null,
  } = props;

  const defaultValues = useGetDefaultProject(currentProject);
  const [tab, setTab] = useState(1);
  const [status, setStatus] = useState(true);
  const [isHot, setIsHot] = useState(true);
  const [isHomePage, setHomePage] = useState(false);
  const [image_Url, setImage_Url] = useState("");
  const [content, setContent] = useState();
  const [isShow, setShow] = useState(false);
  const [isShowFb, setShowFb] = useState(false);
  const [isShowTw, setShowTw] = useState(false);
  const [isShowDocs, setShowDocs] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);
  const [fileDocs, setFileDocs] = useState([]);
  const [fileFb, setFileFb] = useState([]);
  const [fileTempFb, setFileTempFb] = useState([]);
  const [fileTw, setFileTw] = useState([]);
  const [fileTempTw, setFileTempTw] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [listProvinces, setListProvinces] = useState([]);
  const [listInvestors, setListInvestors] = useState([]);
  // Project progress
  const [projectProgress, setProjectProgress] = useState([]);
  const [selectedProjectProgress, setSelectedProjectProgress] = useState("");

  // Project status
  const [projectStatusCache, setProjectStatusCache] = useState([]);
  const [selectedProjectStatusCache, setSelectedProjectStatusCache] =
    useState("");

  // Real Estate Type Cache
  const [realEstateTypeCache, setRealEstateTypeCache] = useState([]);
  const [selectedRealEstateTypeCache, setSelectedRealEstateTypeCache] =
    useState("");

  // Utilities
  const [utilitiesCache, setUtilitiesCache] = useState([]);
  const [selectedUtilitiesCache, setSelectedUtilitiesCache] = useState("");

  // Investor
  const [selectedInvestor, setSelectedInvestor] = useState("");

  // Galleries
  const [galleries, setGalleries] = useState([]);
  const [showGallery, setShowGallery] = useState(false);
  const [tempGalleries, setTempGalleries] = useState([]);
  const [galleryFiles, setGalleryFile] = useState([]);

  // Ground Galleries
  const [groundGalleries, setGroundGalleries] = useState([]);
  const [showGroundGallery, setShowGroundGallery] = useState(false);
  const [tempGroundGalleries, setTempGroundGalleries] = useState([]);
  const [galleryGroundFiles, setGalleryGroundFile] = useState([]);

  const [requireUtility, setRequireUtility] = useState(false);
  const [endDate, setEndDate] = useState();
  const [fileUpload, setFileUpload] = useState([]);
  const [fileUploadTwt, setFileUploadTwt] = useState([]);
  const [fileUploadFb, setFileUploadFb] = useState([]);
  const [fileUploadGalary, setFileUploadGalary] = useState([]);
  const [fileUploadGround, setFileUploadGround] = useState([]);
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabIndexChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // Get files from API
  useEffect(() => {
    if (currentProject) {
      let {
        fbImageFile,
        imageFile,
        twitterImageFile,
        galaryImageFile,
        groundImageFile,
      } = currentProject;
      
      setEndDate(currentProject.endDate);
      const tmpFiles = [];
      const tmpTwFiles = [];
      const tmpFbFiles = [];
      if (imageFile) {
        tmpFiles.push(imageFile);
      }
      if (twitterImageFile) {
        tmpTwFiles.push(twitterImageFile);
      }
      if (fbImageFile) {
        tmpFbFiles.push(fbImageFile);
      }
      setFiles([...tmpFiles]);
      setFileTw([...tmpTwFiles]);
      setFileFb([...tmpFbFiles]);
      setGalleries([...galaryImageFile ? galaryImageFile : []]);
      setGroundGalleries([...galaryImageFile ? galaryImageFile : []]);
    }
  }, [currentProject]);
  const methods = useForm({
    defaultValues,
    mode: "all",
    reValidateMode: "onBlur",
  });
  const {
    register,
    handleSubmit,
    setError,
    errors,
    clearErrors,
    setValue,
    control,
    reset,
    watch,
    formState
  } = methods;
  const { isSubmitting } = formState;
  const selectUtilities = watch("utilities");
  const isValidUtility = useMemo(() => {
    if (selectUtilities) {
      const found = selectUtilities?.find((item) => item === true);
      if (found) {
        return true;
      }
      return false;
    }
    return false;
  }, [selectUtilities]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    setValue("startDate", new Date());
    //setValue('endDate', new Date());
  }, []);

  // Get province
  useEffect(() => {
    async function getProvinces() {
      try {
        const data = await provinceAction.GetLookupProvince();
        const content = data?.content ?? [];
        setListProvinces([...content]);
      } catch (error) {
        setListProvinces([]);
      }
    }
    getProvinces();
  }, []);

  // Get master data
  useEffect(() => {
    async function getMasterData() {
      try {
        const data = await masterDataAction.GetMasterDataFromLangId({ langId });

        const projectProgressRes = data?.projectProgress ?? [];
        const projectStatusCacheRes = data?.projectStatusCache ?? [];
        const realEstateTypeCacheRes = data?.realEstateTypeCache ?? [];
        const utilitiesCacheRes = data?.utilitiesCache ?? [];
        setProjectProgress([...projectProgressRes]);
        setProjectStatusCache([...projectStatusCacheRes]);
        setRealEstateTypeCache([...realEstateTypeCacheRes]);
        setUtilitiesCache([...utilitiesCacheRes]);
      } catch (error) {
        // Inorge error
      }
    }
    getMasterData();
  }, []);

  // Get list investor
  useEffect(() => {
    // async function getAllInvestor() {
    //   try {
    //     const data = await investorAction.GetLookupInvestor({ langId });
    //     const items = data?.content ?? [];
    //     setListInvestors([...items]);
    //   } catch (error) {
    //     setListInvestors([]);
    //   }
    // }
    // getAllInvestor();
  }, []);

  const onChangeContent = (editorContent) => {
    clearErrors(["editorContent"]);
    if (editorContent === "<p><br></p>") {
      setError("editorContent", { type: "required" });
      setContent("");
    } else {
      clearErrors("editorContent");
      setContent(editorContent);
    }
  };

  const onSubmit = async(data) => {
    const {
      translations: formTranslations,
      address,
      investor,
      planning,
      linkYoutube,
    } = data;
    const destructureData = destructureFormData({
      objData: data,
      listUitilies: utilitiesCache,
    });
    const { utilities } = destructureData;
    if (!isValidUtility) {
      return;
    }

    let object = {
      ...destructureData,
    };
    
    if (fileUpload && fileUpload.length > 0) {
      object = {
        ...object,
        image: fileUpload[0],
      };
    }
    if (fileUploadTwt && fileUploadTwt.length > 0) {
      object = {
        ...object,
        imageTwitter: fileUploadTwt[0],
      };
    }
    if (fileUploadFb && fileUploadFb.length > 0) {
      object = {
        ...object,
        imageFb: fileUploadFb[0],
      };
    }

    if (fileUploadGalary && fileUploadGalary.length > 0) {
      object = {
        ...object,
        galaryImages: fileUploadGalary,
      };
    }
    if (fileUploadGround && fileUploadGround.length > 0) {
      object = {
        ...object,
        groundImages: fileUploadGround,
      };
    }

    if (files && files.length > 0) {
      object = {
        ...object,
        imageId: files[0].fileId,
      };
    }
    if (fileTw && fileTw.length > 0) {
      object = {
        ...object,
        twitterImageId: fileTw[0].fileId,
      };
    }
    if (fileFb && fileFb.length > 0) {
      object = {
        ...object,
        fbImageId: fileFb[0].fileId,
      };
    }
    const galleryIds = [...galleries].map((item) => item?.fileId);
    const groundGalleryIds = [...groundGalleries].map((item) => item?.fileId);
    const translations = formTranslations.map((item) => {
      return { ...item, description: content };
    });

    object = {
      ...object,
      addressId: address?.value ? address.value : currentProject.addressId,
      investorId: investor?.value ? investor.value : currentProject.investorId,
      galaryImage: galleryIds,
      groundImage: groundGalleryIds,
      linkYoutube,
      translations,
    };
    if (planning?.value) object = { ...object, planningId: planning.value };
    else if (currentProject)
      object = { ...object, planningId: currentProject.planningId };

    if (endDate) {
      object = {
        ...object,
        endDate: endDate,
      };
    } else {
      object = {
        ...object,
        endDate: null,
      };
    }
    if (!isEdit) {
      const formData = new FormData();
      buildFormData(formData, object);
      fileUploadGalary &&
        fileUploadGalary.length > 0 &&
        fileUploadGalary.map((item) => formData.append("galaryImages", item));
      fileUploadGround &&
        fileUploadGround.length > 0 &&
        fileUploadGround.map((item) => formData.append("groundImages", item));
      await projectAction
        .ProjectCreateWithUpload(formData)
        .then((res) => {
          history.push(UrlCollection.ProjectManagement)
          ShowNotification(
            viVN.Success.ProjectAddSuccess,
            NotificationMessageType.Success
          );
        })
        .catch(
          (err) => viVN.Errors[err.errorType],
          NotificationMessageType.Error,
        );
    } else {
      const translationObj = translations ? translations[0] : {};
      const translationRes = currentProject?.translations;
      const translationObjRes = translationRes ? translationRes[0] : null;
      const projectIdTrans = translationObjRes?.id;
      delete object.translations;
      const dataEdit = {
        id: projectIdTrans,
        coreId: currentProject?.id,
        core: { ...object, id: currentProject?.id, linkYoutube },
        ...translationObj,
      };

      const formData = new FormData();
      buildFormData(formData, dataEdit);
      fileUploadGalary &&
        fileUploadGalary.length > 0 &&
        fileUploadGalary.map((item) => formData.append("galaryImages", item));
      fileUploadGround &&
        fileUploadGround.length > 0 &&
        fileUploadGround.map((item) => formData.append("groundImages", item));
      await projectAction
        .ProjectUpdateWithUpload(formData)
        .then((res) => {
          ShowNotification(
            viVN.Success.ProjectEditSuccess,
            NotificationMessageType.Success
          );
        })
        .catch(
          (err) => viVN.Errors[err.errorType],
          NotificationMessageType.Error,
        );
    }
  };

  const handleChangeStatus = (event) => {
    event.persist();
    setStatus(event.target.checked);
  };

  const handleChangeHot = (event) => {
    event.persist();
    setIsHot(event.target.checked);
  };

  const handleChangeHomePage = (event) => {
    event.persist();
    setHomePage(event.target.checked);
  };

  const onOpenSelectFile = () => {
    setShow(true);
    setFilesTemp(files);
  };

  const onOpenSelectFileFb = () => {
    setShowFb(true);
    setFileTempFb(fileFb);
  };

  const onOpenSelectFileTw = () => {
    setShowTw(true);
    setFileTempTw(fileTw);
  };

  const onCloseSelectFileFb = () => {
    setShowDocs(false);
    setFileFb(fileTempFb);
  };

  const onSaveSelectFileFb = () => {
    setShowFb(false);
  };

  const onCloseSelectFileTw = () => {
    setShowTw(false);
    setFileTw(fileTempTw);
  };

  const onSaveSelectFileTw = () => {
    setShowTw(false);
  };

  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
  };

  const onSaveSelectFile = () => {
    setShow(false);
  };

  // Handle gallery
  const onOpenGallery = () => {
    setShowGallery(true);
  };

  const onOpenGroundGallery = () => {
    setShowGroundGallery(true);
  };

  const onCloseGalleryDialog = () => {
    setShowGallery(false);
  };

  const onCloseGroundGalleryDialog = () => {
    setShowGroundGallery(false);
  };

  const onSaveGalleries = () => {
    setGalleries([...galleries, ...tempGalleries]);
    setShowGallery(false);
  };

  const onSaveGroundGalleries = () => {
    setGroundGalleries([...groundGalleries, ...tempGroundGalleries]);
    setShowGroundGallery(false);
  };

  const deleteImageSingle = (evt, fileId) => {
    evt.preventDefault();
    const arr = [...galleries].filter((item) => item?.fileId !== fileId);
    setGalleries(arr);
  };

  const deleteGroundImageSingle = (evt, fileId) => {
    evt.preventDefault();
    const arr = [...groundGalleries].filter((item) => item?.fileId !== fileId);
    setGroundGalleries(arr);
  };
  
  const onGoBack = () => {
    const queryParams = window.location.search
    history.push(UrlCollection.ProjectManagement + queryParams)
  };
  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-sm-flex align-items-center mb-2" onClick={onGoBack} style={{ cursor: "pointer", width: 100}}>
            <ArrowBackIos fontSize="small"></ArrowBackIos> Quay lại
          </div>
          <div className="d-sm-flex align-items-center justify-content-between mb-3">
            <h1 className="h3 mb-0 text-gray-800">
              {`${isEdit ? "Sửa" : "Thêm"} dự án`}
            </h1>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={!isSubmitting ? <SaveIcon></SaveIcon> : <CircularProgress size={20} color="white"/>}
              disabled={isSubmitting}
            >
              Lưu
            </Button>
          </div>
          <div className="pb-4">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${tab === 1 ? "active" : ""}`}
                  onClick={() => setTab(1)}
                >
                  Thông tin dự án
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${tab === 2 ? "active" : ""}`}
                  onClick={() => setTab(2)}
                >
                  Thông tin SEO
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={tab}>
              <TabPane tabId={1}>
                <Row>
                  <Col sm="12">
                    <div className="row pt-4">
                      <div className="col-9">
                        <Grid container className={classes.root} spacing={2}>
                          <input
                            type="hidden"
                            value={langId}
                            name={PR_LANG_ID}
                            ref={register()}
                          />
                          <Grid item xs={12}>
                            <div className="form-group">
                              <label className="text-dark">
                                Tên dự án<span className="required"></span>
                              </label>
                              <TextField
                                name={PR_NAME}
                                error={
                                  checkIsError({
                                    errors,
                                    name: PR_NAME,
                                    isArr: true,
                                  }) ||
                                  checkIsError({
                                    errors,
                                    name: PR_NAME,
                                    type: MAX_LENGTH,
                                    isArr: true,
                                  })
                                }
                                fullWidth
                                type="text"
                                className="form-control"
                                inputProps={{ maxLength: 150 }}
                                inputRef={register({
                                  required: true,
                                  [MAX_LENGTH]: 150,
                                })}
                              />
                              {checkIsError({
                                errors,
                                name: PR_NAME,
                                isArr: true,
                              }) && (
                                <span className="error">
                                  Trường này là bắt buộc
                                </span>
                              )}
                              {checkIsError({
                                errors,
                                name: PR_NAME,
                                type: MAX_LENGTH,
                                isArr: true,
                              }) && (
                                <span className="error">
                                  Trường này không quá 150 ký tự
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div className="form-group">
                              <label className="text-dark">Mô tả ngắn</label>
                              <textarea
                                name={PR_SHORT_DESCRIPTION}
                                rows="4"
                                ref={register({
                                  required: false,
                                  [MAX_LENGTH]: 500,
                                })}
                                maxLength="500"
                                className={
                                  "form-control" +
                                  (checkIsError({
                                    errors,
                                    name: PR_SHORT_DESCRIPTION,
                                    isArr: true,
                                  })
                                    ? " is-invalid"
                                    : "")
                                }
                              ></textarea>
                              {checkIsError({
                                errors,
                                name: PR_SHORT_DESCRIPTION,
                                isArr: true,
                              }) && (
                                <span className="error">
                                  Trường này là bắt buộc
                                </span>
                              )}
                              {checkIsError({
                                errors,
                                name: PR_SHORT_DESCRIPTION,
                                isArr: true,
                                type: MAX_LENGTH,
                              }) && (
                                <span className="error">
                                  Trường này không quá 500 ký tự
                                </span>
                              )}
                            </div>
                          </Grid>
                          {/* Project Content */}
                          <Grid item xs={12}>
                            <ProjectContent
                              onChangeContent={onChangeContent}
                              currentProject={currentProject}
                              contentValue={content}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <SelectProvince listProvinces={listProvinces} />
                            {errors?.provinceId && (
                              <span className="error">
                                Trường này là bắt buộc
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={9}>
                            {/* Address */}
                            <FormInputAddress
                              title="Địa chỉ"
                              name="address"
                              placeholder="Địa chỉ"
                              langId={langId}
                              validate={{ required: true }}
                            />
                            {errors?.address && (
                              <span className="error">
                                Trường này là bắt buộc
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <UtilitiesList
                              listUtilities={utilitiesCache}
                              currentUtilities={currentProject?.utilities}
                              isEdit={isEdit}
                              isValidUtility={isValidUtility}
                            />
                          </Grid>
                          {/* List untilities */}
                          <Grid item xs={12}>
                            {/* Image */}
                            <ProjectImage
                              isShow={isShow}
                              files={files}
                              fileUpload={fileUpload}
                              onOpenSelectFile={onOpenSelectFile}
                              setFileUpload={setFileUpload}
                            />
                          </Grid>

                          {/* Gallery */}
                          <Grid item xs={12}>
                            <ProjectGallery
                              galleries={galleries}
                              setFileUploadGalary={setFileUploadGalary}
                              onOpenSelectFileLib={onOpenGallery}
                              deleteImageSingle={deleteImageSingle}
                              classes={classes}
                            />
                          </Grid>

                          {/* Ground Gallery */}
                          <Grid item xs={12}>
                            <ProjectGroundImage
                              groundImages={groundGalleries}
                              onOpenSelectFileLib={onOpenGroundGallery}
                              deleteImageSingle={deleteGroundImageSingle}
                              setFileUploadGround={setFileUploadGround}
                              classes={classes}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <div className="col-3 col-right">
                        <Grid container className={classes.root} spacing={2}>
                          <Grid item xs={12}>
                            <FormAsyncPlanning
                              name="planning"
                              placeholder="Chọn quy hoạch..."
                              title="Chọn quy hoạch"
                              defaultValue={{
                                value: currentProject?.planningId,
                                label: currentProject?.planningName,
                              }}
                            />
                          </Grid>
                          {/* Apartment number */}
                          <Grid item xs={12}>
                            <div className="form-group">
                              <label className="text-dark">Số căn hộ</label>
                              <TextField
                                name={"apartmentNumber"}
                                error={checkIsError({
                                  errors,
                                  name: "apartmentNumber",
                                })}
                                fullWidth
                                type="text"
                                className="form-control"
                                inputProps={{ maxLength: 150 }}
                                inputRef={register({
                                  required: false,
                                  validate: validateNumber,
                                })}
                              />
                              {checkIsError({
                                errors,
                                name: "apartmentNumber",
                              }) && (
                                <span className="error">
                                  Trường này là bắt buộc
                                </span>
                              )}
                              {checkIsError({
                                errors,
                                name: "apartmentNumber",
                                type: "validate",
                              }) && (
                                <span className="error">
                                  {REQUIRED_NUMBER_MSG}
                                </span>
                              )}
                            </div>
                          </Grid>
                          {/* Acreage */}
                          <Grid item xs={12}>
                            <div className="form-group">
                              <label className="text-dark">
                                Diện tích<span className="required"></span>
                              </label>
                              <TextField
                                name={"acreage"}
                                error={checkIsError({
                                  errors,
                                  name: "acreage",
                                })}
                                fullWidth
                                type="text"
                                className="form-control"
                                inputProps={{ maxLength: 150 }}
                                inputRef={register({
                                  required: true,
                                  validate: validateNumber,
                                })}
                              />
                              {checkIsError({ errors, name: "acreage" }) && (
                                <span className="error">
                                  Trường này là bắt buộc
                                </span>
                              )}
                              {checkIsError({
                                errors,
                                name: "acreage",
                                type: "validate",
                              }) && (
                                <span className="error">
                                  {REQUIRED_NUMBER_MSG}
                                </span>
                              )}
                            </div>
                          </Grid>
                          {/* Start date */}
                          <Grid item xs={12}>
                            <div className="form-group">
                              <label className="text-dark">Ngày bắt đầu</label>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Controller
                                  render={({
                                    ref,
                                    onChange,
                                    value,
                                    onBlur,
                                  }) => (
                                    <DatePicker
                                      id="startDate"
                                      inputRef={ref}
                                      onBlur={onBlur}
                                      onChange={onChange}
                                      value={value}
                                      format="dd/MM/yyyy"
                                      fullWidth
                                      showTodayButton={true}
                                      error={
                                        errors.startDate &&
                                        errors.startDate.type === "required"
                                      }
                                    />
                                  )}
                                  name={"startDate"}
                                  control={control}
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                          </Grid>
                          {/* End date */}
                          <Grid item xs={12}>
                            <div className="form-group">
                              <label className="text-dark">Ngày kết thúc</label>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  id="endDate"
                                  onChange={(date) => {
                                    setEndDate(date);
                                  }}
                                  value={endDate}
                                  format="dd/MM/yyyy"
                                  fullWidth
                                  showTodayButton={true}
                                  error={
                                    errors.endDate &&
                                    errors.endDate.type === "required"
                                  }
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                          </Grid>
                          {/* Phone number */}
                          <Grid item xs={12}>
                            <div className="form-group">
                              <label className="text-dark">Số điện thoại</label>
                              <TextField
                                name={"phoneNumber"}
                                error={checkIsError({
                                  errors,
                                  name: "phoneNumber",
                                })}
                                fullWidth
                                type="text"
                                className="form-control"
                                inputProps={{ maxLength: 150 }}
                                inputRef={register({
                                  required: false,
                                  validate: validatePhoneNumber,
                                })}
                              />
                              {checkIsError({
                                errors,
                                name: "phoneNumber",
                              }) && (
                                <span className="error">
                                  Trường này là bắt buộc
                                </span>
                              )}

                              {checkIsError({
                                errors,
                                name: "phoneNumber",
                                type: "validate",
                              }) && (
                                <span className="error">
                                  Số điện thoại không hợp lệ
                                </span>
                              )}
                            </div>
                          </Grid>

                          {/* Building Density */}
                          <Grid item xs={12}>
                            <div className="form-group">
                              <label className="text-dark">
                                Mật độ xây dựng
                              </label>
                              <TextField
                                name={"buildingDensity"}
                                error={checkIsError({
                                  errors,
                                  name: "buildingDensity",
                                })}
                                fullWidth
                                type="text"
                                className="form-control"
                                inputProps={{ maxLength: 150 }}
                                inputRef={register({
                                  required: false,
                                  validate: validateNumber,
                                })}
                              />
                              {checkIsError({
                                errors,
                                name: "buildingDensity",
                              }) && (
                                <ErrorMessage message={REQUIRED_FIELD_MSG} />
                              )}
                              {checkIsError({
                                errors,
                                name: "buildingDensity",
                                type: "validate",
                              }) && (
                                <ErrorMessage message={REQUIRED_NUMBER_MSG} />
                              )}
                            </div>
                          </Grid>

                          {/* Investor */}
                          <Grid item xs={12}>
                            <FormAsynceSelectPaginationInvestor
                              name="investor"
                              placeholder="Chủ đầu tư"
                              title="Chủ đầu tư"
                              defaultValue={{
                                value: currentProject?.investor?.id,
                                label: currentProject?.investor?.name,
                              }}
                            />
                            {/* <SelectInvestor listOptions={listInvestors} /> */}
                            {errors?.investorId && (
                              <span className="error">
                                Trường này là bắt buộc
                              </span>
                            )}
                          </Grid>

                          {/* Status Id */}
                          <Grid item xs={12}>
                            <SelectProjectStatus
                              listOptions={projectStatusCache}
                            />
                            {errors?.statusId && (
                              <span className="error">
                                Trường này là bắt buộc
                              </span>
                            )}
                          </Grid>

                          {/* Project progress */}
                          <Grid item xs={12}>
                            <SelectProjectProgress
                              listOptions={projectProgress}
                            />
                            {errors?.progressId && (
                              <span className="error">
                                Trường này là bắt buộc
                              </span>
                            )}
                          </Grid>

                          {/* Project real estate ype */}
                          <Grid item xs={12}>
                            <SelectRealType listOptions={realEstateTypeCache} />
                            {errors?.realEstateTypeId && (
                              <span className="error">
                                Trường này là bắt buộc
                              </span>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container>
                              {/* Status */}
                              <Grid item xs={6}>
                                <ProjectActive
                                  defaultValue={defaultValues?.isActive}
                                />
                              </Grid>

                              <Grid item xs={6}>
                                <ProjectIsFeature
                                  defaultChecked={defaultValues?.isFeature}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                              {
                                isEdit ? (
                                  <Grid item xs={12}>
                                  <div className="form-group">
                                      <label className="text-dark">
                                       Liên kết
                                      </label>
                                      <TextField
                                        name={PR_SLUG}
                                        fullWidth
                                        type="text"
                                        className="form-control"
                                        inputRef={register({
                                          required: false,
                                        })}
                                      />
                                    </div>
                                  </Grid>
                                ) : ""
                              }
                          <Grid item xs={12}>
                            <div className="form-group">
                              <label className="text-dark">
                                Thêm video từ Youtube
                              </label>
                              <TextField
                                name={"linkYoutube"}
                                placeholder={
                                  "https://www.youtube.com/watch?v=uEP92B9zB5c"
                                }
                                error={
                                  checkIsError({
                                    errors,
                                    name: "linkYoutube",
                                  }) ||
                                  checkIsError({
                                    errors,
                                    name: "linkYoutube",
                                  })
                                }
                                defaultValue={currentProject?.linkYoutube}
                                fullWidth
                                type="text"
                                className="form-control"
                                inputRef={register({
                                  pattern:
                                    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                })}
                              />
                              {checkIsError({
                                errors,
                                name: "linkYoutube",
                                type: "pattern",
                              }) && (
                                <span className="error">
                                  Trường này phải là liên kết
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={2}>
                <Row>
                  <Col sm="12">
                    <div className="row pt-4">
                      <div className="col-6">
                        {/* Short Description SEO */}
                        <Grid item xs={12}>
                          <div className="form-group">
                            <label className="text-dark">Mô tả ngắn SEO</label>
                            <textarea
                              name={PR_META_DESCRIPTION}
                              rows="7"
                              ref={register({
                                required: false,
                                [MAX_LENGTH]: 500,
                              })}
                              maxLength="500"
                              className={
                                "form-control" +
                                (checkIsError({
                                  errors,
                                  name: PR_META_DESCRIPTION,
                                  isArr: true,
                                })
                                  ? " is-invalid"
                                  : "")
                              }
                            ></textarea>
                            {checkIsError({
                              errors,
                              name: PR_META_DESCRIPTION,
                              isArr: true,
                            }) && (
                              <span className="error">
                                Trường này là bắt buộc
                              </span>
                            )}
                            {checkIsError({
                              errors,
                              name: PR_META_DESCRIPTION,
                              isArr: true,
                              type: MAX_LENGTH,
                            }) && (
                              <span className="error">
                                Trường này không quá 500 ký tự
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="form-group">
                            <label className="text-dark">Tiêu đề SEO</label>
                            <TextField
                              name={PR_META_TITLE}
                              error={
                                checkIsError({
                                  errors,
                                  name: PR_META_TITLE,
                                  isArr: true,
                                }) ||
                                checkIsError({
                                  errors,
                                  name: PR_META_TITLE,
                                  isArr: true,
                                  type: MAX_LENGTH,
                                })
                              }
                              fullWidth
                              type="text"
                              className="form-control"
                              inputProps={{ maxLength: 200 }}
                              inputRef={register({
                                required: false,
                                maxLength: 150,
                              })}
                            />
                            {checkIsError({
                              errors,
                              name: PR_META_TITLE,
                              isArr: true,
                            }) && (
                              <span className="error">
                                Trường này là bắt buộc
                              </span>
                            )}
                            {checkIsError({
                              errors,
                              name: PR_META_TITLE,
                              isArr: true,
                              type: MAX_LENGTH,
                            }) && (
                              <span className="error">
                                Trường này không quá 150 ký tự
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="form-group">
                            <label className="text-dark">Từ khoá SEO</label>
                            <TextField
                              name={PR_META_KEYWORD}
                              error={
                                checkIsError({
                                  errors,
                                  name: PR_META_KEYWORD,
                                  isArr: true,
                                }) ||
                                checkIsError({
                                  errors,
                                  name: PR_META_KEYWORD,
                                  isArr: true,
                                  type: MAX_LENGTH,
                                })
                              }
                              fullWidth
                              type="text"
                              className="form-control"
                              inputRef={register({
                                required: false,
                                maxLength: 150,
                              })}
                              inputProps={{ maxLength: 150 }}
                            />
                            {checkIsError({
                              errors,
                              name: PR_META_KEYWORD,
                              isArr: true,
                            }) && (
                              <span className="error">
                                Trường này là bắt buộc
                              </span>
                            )}
                            {checkIsError({
                              errors,
                              name: PR_META_KEYWORD,
                              isArr: true,
                              type: MAX_LENGTH,
                            }) && (
                              <span className="error">
                                Trường này không quá 150 ký tự
                              </span>
                            )}
                          </div>
                        </Grid>
                      </div>
                      <div className="col-6">
                        <Grid item xs={12}>
                          <div className="form-group">
                            <label className="text-dark">
                              Mô tả share Facebook
                            </label>
                            <textarea
                              name={PR_FB_DESCRIPTION}
                              rows="7"
                              ref={register({
                                required: false,
                                [MAX_LENGTH]: 500,
                              })}
                              maxLength="500"
                              className={
                                "form-control" +
                                (checkIsError({
                                  errors,
                                  name: PR_FB_DESCRIPTION,
                                  isArr: true,
                                })
                                  ? " is-invalid"
                                  : "")
                              }
                            ></textarea>
                            {checkIsError({
                              errors,
                              name: PR_FB_DESCRIPTION,
                              isArr: true,
                            }) && (
                              <span className="error">
                                Trường này là bắt buộc
                              </span>
                            )}
                            {checkIsError({
                              errors,
                              name: PR_FB_DESCRIPTION,
                              isArr: true,
                              type: MAX_LENGTH,
                            }) && (
                              <span className="error">
                                Trường này không quá 500 ký tự
                              </span>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="form-group">
                            <label className="text-dark">
                              Mô tả share Twitter
                            </label>
                            <textarea
                              name={PR_TW_DESCRIPTION}
                              rows="7"
                              ref={register({
                                required: false,
                                maxLength: 500,
                              })}
                              maxLength="500"
                              className={
                                "form-control" +
                                (checkIsError({
                                  errors,
                                  name: PR_TW_DESCRIPTION,
                                  isArr: true,
                                })
                                  ? " is-invalid"
                                  : "")
                              }
                            ></textarea>
                            {checkIsError({
                              errors,
                              name: PR_TW_DESCRIPTION,
                              isArr: true,
                            }) && (
                              <span className="error">
                                Trường này là bắt buộc
                              </span>
                            )}
                            {checkIsError({
                              errors,
                              name: PR_TW_DESCRIPTION,
                              isArr: true,
                              type: MAX_LENGTH,
                            }) && (
                              <span className="error">
                                Trường này không quá 500 ký tự
                              </span>
                            )}
                          </div>
                        </Grid>
                      </div>
                    </div>
                    <hr />
                    <Grid item xs={12}>
                      <Grid container>
                        {/* Twitter Image */}
                        <Grid item xs={6}>
                          <ProjectImageTw
                            isShowTw={isShowTw}
                            fileTw={fileTw}
                            onOpenSelectFileTw={onOpenSelectFileTw}
                            setFileUploadTwt={setFileUploadTwt}
                          />
                        </Grid>
                        {/* Facebook Image */}
                        <Grid item xs={6}>
                          <ProjectImageFb
                            isShowFb={isShowFb}
                            fileFb={fileFb}
                            onOpenSelectFileFb={onOpenSelectFileFb}
                            setFileUploadFb={setFileUploadFb}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </form>
      </FormProvider>
      {isShow && (
        <Dialog
          onClose={onCloseSelectFile}
          open={isShow}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={files}
              setFiles={setFiles}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {files && files.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
      {isShowFb && (
        <Dialog
          onClose={onCloseSelectFileFb}
          open={isShowFb}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFileFb}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={fileFb}
              setFiles={setFileFb}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFileFb}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {fileFb && fileFb.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFileFb}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {isShowTw && (
        <Dialog
          onClose={onCloseSelectFileTw}
          open={isShowTw}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFileTw}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={fileTw}
              setFiles={setFileTw}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFileTw}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {fileTw && fileTw.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFileTw}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}

      {showGallery && (
        <SelectImageDialog
          onClose={onCloseGalleryDialog}
          openDialog={showGallery}
          setFiles={setTempGalleries}
          files={galleries}
          tmpFiles={tempGalleries}
          onSave={onSaveGalleries}
          classes={classes}
        />
      )}

      {showGroundGallery && (
        <SelectImageDialog
          onClose={onCloseGroundGalleryDialog}
          openDialog={showGroundGallery}
          setFiles={setTempGroundGalleries}
          files={groundGalleries}
          tmpFiles={tempGroundGalleries}
          onSave={onSaveGroundGalleries}
          classes={classes}
        />
      )}
    </div>
  );
}
