import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Configs } from "../../common/config";
import { useForm } from "react-hook-form";

//--- Material Control
import {
  Button,
  TextField,
  IconButton,
  Tooltip,
  Popover,
  Fab,
  Box,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ButtonGroup from "@material-ui/core/ButtonGroup";

//--- Styles
import "./styles.scss";

//--- Material Icon
import DeleteCircle from "@material-ui/icons/DeleteForever";
import AddCircle from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import RefreshIcon from "@material-ui/icons/Refresh";

//--- Component
import ListLandPriceInfos from "./list-land-price-infos/list-land-price-infos.view";
import AddLandPriceInfo from "./add-land-price-info/add-land-price-info.view";
import EditLandPriceInfo from "./edit-land-price-info/edit-land-price-info.view";
import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";
// import ViewLandPriceInfo from './view-land-price-info/view-land-price-info.view';

//--- Notifications
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";
import * as viVN from "../../language/vi-VN.json";

//--- Redux store
import * as masterDataAction from "../../redux/store/master-data/master-data.store";
import * as landPriceInfoActions from "../../redux/store/land-price-info/land-price-info.store";
import * as provinceAction from "../../redux/store/province-management/province.store";
import * as appActions from "../../core/app.store";
function LandPriceInfo(props) {
  const { langId } = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const { pathname } = props.location;
  const [landPriceInfoModels, setLandPriceInfoModels] = useState();
  const [totalItemCount, setTotalItemCount] = useState();
  const [searchData, setSearchData] = useState({
    keyword: "",
  });
  const [landPriceInfoId, setLandPriceInfoId] = useState();

  const [isDelete, setIsDelete] = useState(false);
  const [isShowTrash, setIsShowTrash] = useState(false);
  const [soilTypes, setSoilTypes] = useState(null);
  const [realEstateTypes, setRealEstateTypes] = useState(null);
  const [provinceSelect, setProvinceSelect] = useState();

  const [requestParams, setRequestParams] = useState({
    page: 1,
    rowsPerPage: Configs.DefaultPageSize,
    orderBy: "modifiedDate",
    order: "desc",
    keyword: "",
  });

  const { register, handleSubmit } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  // Get master data
  useEffect(() => {
    async function getMasterData() {
      try {
        const data = await masterDataAction.GetMasterDataFromLangId({ langId });
        const soilTypeCacheRes = data?.soilTypeCache ?? [];
        setSoilTypes([...soilTypeCacheRes]);
        const realEstateTypeCacheRes = data?.realEstateTypeCache ?? [];
        setRealEstateTypes([...realEstateTypeCacheRes]);
      } catch (error) {
        console.log("error-", error);
      }
    }
    getMasterData();
  }, [langId]);

  const GetListAll = () => {
    dispatch(appActions.ShowLoading(true))
    landPriceInfoActions
      .GetListAll(
        langId,
        requestParams.page,
        requestParams.rowsPerPage,
        requestParams.orderBy + " " + requestParams.order,
        requestParams.keyword !== "" ? requestParams.keyword : undefined,
        isDelete
      )
      .then((res) => {
        setLandPriceInfoModels(res.content.items);
        setTotalItemCount(res.content.totalItemCount);
        dispatch(appActions.ShowLoading(false))
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (langId) {
      GetListAll();
    }
  }, [pathname, langId, requestParams, isDelete]);

  useEffect(() => {
    Promise.all([GetLookupProvince()])
      .then((res) => {
        const [provinceLookupModels] = res;
        setProvinceSelect(
          provinceLookupModels && provinceLookupModels.content
            ? provinceLookupModels.content
            : []
        );
      })
      .catch((err) => {});
  }, []);

  const GetLookupProvince = () => {
    return new Promise((resolve, reject) => {
      provinceAction.GetLookupProvince().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  //--- Dialog
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  // const [openViewLandPriceInfoDialog, setOpenViewLandPriceInfoDialog] = useState(false);

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleOpenEditDialog = (landPriceInfoId) => {
    setLandPriceInfoId(landPriceInfoId);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleOpenDeleteDialog = (landPriceInfoId) => {
    setLandPriceInfoId(landPriceInfoId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  //--- Filter
  const [filterSection, setFilterSection] = useState(null);

  const handleClickFilter = (event) => {
    setFilterSection(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterSection(null);
  };

  const openFilter = Boolean(filterSection);
  const idFilter = openFilter ? "popoverSlider" : undefined;
  const handleRestore = (id) => {
    // landPriceInfoActions.RestoreLandPriceInfo(id).then(
    //   (res) => {
    //     if (res && res.content && res.content.status) {
    //       setRequestParams(x => ({
    //         ...x,
    //         page: 1
    //       }));
    //       ShowNotification(
    //         viVN.Success.LandPriceInfoRestoreSuccess,
    //         NotificationMessageType.Success
    //       );
    //     }
    //   },
    //   (err) => {
    //     err &&
    //       err.errorType &&
    //       ShowNotification(
    //         viVN.Errors[err.errorType],
    //         NotificationMessageType.Error
    //       );
    //   }
    // );
  };
  const handleDelete = () => {
    landPriceInfoActions.LandPriceInfoDelete(landPriceInfoId).then(
      (res) => {
        if (res && res.content && res.content.status) {
          GetListAll();
          handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.LandPriceInfoDeleteSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };

  const handleClearAllField = () => {
    refresh();
    setFilterSection(null);
  };

  const onSubmit = (data) => {
    setRequestParams((x) => ({
      ...x,
      page: 1,
    }));
    handleCloseFilter();
  };

  const refresh = () => {
    setRequestParams((x) => ({
      ...x,
      page: 1,
      order: "desc",
      orderBy: "modifiedDate",
      keyword: "",
    }));
  };

  const handleChangeTrash = (event) => {
    setIsShowTrash(event.target.checked);
    setIsDelete(event.target.checked ? true : false);
    setRequestParams((x) => ({
      ...x,
      page: 1,
    }));
  };

  const handleChangeSearchForm = (event) => {
    setRequestParams({
      ...requestParams,
      [event.target.name]: event.target.value,
    });
  };

  const handleEmptyTrash = () => {
    landPriceInfoActions.EmptyTrash().then(
      (res) => {
        if (res && res.content && res.content.status) {
          setRequestParams((x) => ({
            ...x,
            page: 1,
          }));
          //handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.LandPriceInfoDeleteSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };

  return (
    <div className="slider">
      <div className="d-sm-flex align-items-center justify-content-between mb-3">
        <h1 className="h3 mb-0 text-gray-800">
          Thông tin giá đất
          <Tooltip title="Làm mới">
            <Fab
              color="primary"
              aria-label="filter"
              size="small"
              onClick={refresh}
              className="ml-2"
            >
              <RefreshIcon />
            </Fab>
          </Tooltip>
          <span>&nbsp;&nbsp;</span>
          <FormControlLabel
            control={
              <Switch
                checked={isShowTrash}
                onChange={handleChangeTrash}
                name="checkedB"
                color="primary"
              />
            }
            label="Thùng rác"
          />
        </h1>
        <ButtonGroup
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          {isShowTrash && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleEmptyTrash}
              startIcon={<DeleteCircle />}
            >
              Xóa thùng rác
            </Button>
            
          )}
                
        </ButtonGroup>
        <div>
        <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAddDialog}
            startIcon={<AddCircle />}
          >
            Thêm Thông tin giá đất
          </Button>
        </div>
       
              
      </div>
      <Box display="flex" justifyContent="flex-end" flexWrap="nowrap" className="info-section">
        <label className="mr-4"><span className="custom-shape square blue"></span> Giá nhà nước</label>
        <label><span className="custom-shape square green"></span> Giá thị trường</label>
      </Box>
      <div className="d-flex justify-content-between align-items-end">
        <form id="formSearch" onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex align-items-end">
            <div className="form-group">
              <TextField
                className="w-100"
                name="keyword"
                variant="outlined"
                placeholder="Từ khoá"
                value={searchData.keyword}
                onChange={handleChangeSearchForm}
                inputRef={register}
              />
            </div>
            <div className="form-group ml-2">
              <Button variant="contained" color="primary" onClick={onSubmit} className="ml-2">
                <SearchIcon fontSize="small" /> Tìm kiếm
              </Button>
              <Button
                variant="contained"
                className="ml-2"
                onClick={handleClearAllField}
              >
                <ClearAllIcon fontSize="small" /> Bỏ lọc
              </Button>
            </div>
          </div>
        </form>
        <div className="form-group">
          
        </div>
      </div>
      {landPriceInfoModels ? (
        <ListLandPriceInfos
          totalItemCount={totalItemCount}
          landPriceInfoModels={landPriceInfoModels}
          editAction={handleOpenEditDialog}
          deleteAction={handleOpenDeleteDialog}
          restoreAction={handleRestore}
          setRequestParams={setRequestParams}
          requestParams={requestParams}
        />
      ) : (
        ""
      )}
      {openAddDialog && (
        <AddLandPriceInfo
          isOpen={openAddDialog}
          onClose={handleCloseAddDialog}
          onSuccess={handleCloseAddDialog}
          setRequestParams={setRequestParams}
          soilTypes={soilTypes}
          realEstateTypes={realEstateTypes}
          langId={langId}
          provinceSelect={provinceSelect}
        />
      )}

      {openEditDialog && (
        <EditLandPriceInfo
          isOpen={openEditDialog}
          onClose={handleCloseEditDialog}
          onSuccess={handleCloseEditDialog}
          setRequestParams={setRequestParams}
          landPriceInfoId={landPriceInfoId}
          soilTypes={soilTypes}
          realEstateTypes={realEstateTypes}
          langId={langId}
          provinceSelect={provinceSelect}
        />
      )}

      {openDeleteDialog && (
        <DeleteDialog
          isOpen={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onSuccess={handleDelete}
          header={"Xác nhận xóa"}
          content={"Bạn có chắc chắn muốn xóa?"}
        />
      )}
      {/* {openViewLandPriceInfoDialog && (
        <ViewLandPriceInfo
          isOpen={openViewLandPriceInfoDialog}
          onClose={handleCloseViewLandPriceInfoDialog}
          filePreview={filePreview}
        />
      )} */}
    </div>
  );
}

export default connect(null, null)(LandPriceInfo)