import { IconButton, TextField, Tooltip } from "@material-ui/core";
import React, { Component } from "react";
import FormInputCurrency from "../../../components/FormItem/FormInputCurrency";
import DeleteIcon from "@material-ui/icons/Delete";
import "./style.scss";
import moment from "moment";
function HistoryTaxView(props) {
  const { item , onChangeRemoveRowHistoryTax, onChangeRowHistoryTax} = props;
  return (
    <div className="container-history-price" key={item.id}>
      <div className="form-group row">
        <div className="col-12 col-md-1 col-lg-1 d-flex align-items-center justify-content-center">
          <Tooltip title="Xóa">
            <IconButton aria-label="delete" onClick={()=>onChangeRemoveRowHistoryTax(item.id)}>
              <DeleteIcon className="text-danger" />
            </IconButton>
          </Tooltip>
        </div>

        <div className="col-12 col-md-3 col-lg-3">
          <label className="text-dark">Ngày áp dụng</label>
          <TextField
            type="date"
            color="primary"
            value={item.dateApply ? moment(new Date(item.dateApply)).format('YYYY-MM-DD'): ''}
            onChange={e=>onChangeRowHistoryTax(item.id, 'dateApply', e.target.value)}
            className="form-control p-0 mt-0"
          />
        </div>
        <div className="col-12 col-md-2 col-lg-2">
          <label className="text-dark">Tiền thuế</label>
          <TextField
            name="tax"
            fullWidth
            value={item.tax}
            type="number"
            className="form-control"
            onChange={e=>onChangeRowHistoryTax(item.id, 'tax', e.target.value)}
          />
        </div>
        <div className="col-12 col-md-2 col-lg-2">
          <label className="text-dark">% thay đổi thuế</label>
          <TextField
            name="changeTaxRate"
            fullWidth
            type="number"
            value={item.changeTaxRate}
            className="form-control"
            onChange={e=>onChangeRowHistoryTax(item.id, 'changeTaxRate', e.target.value)}
          />
        </div>
        <div className="col-12 col-md-2 col-lg-2">
          <label className="text-dark">Giá tính thuế </label>
          <TextField
            name="taxTax "
            fullWidth
            type="number"
            value={item.taxTax}
            className="form-control"
            onChange={e=>onChangeRowHistoryTax(item.id, 'taxTax', e.target.value)}
          />
        </div>
        <div className="col-12 col-md-2 col-lg-2">
          <label className="text-dark">% thay đổi </label>
          <TextField
            name="changeTaxTax"
            fullWidth
            type="number"
            value={item.changeTaxTax}
            className="form-control"
            onChange={e=>onChangeRowHistoryTax(item.id, 'changeTaxTax', e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

export default HistoryTaxView;
