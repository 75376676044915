import { IconButton, TextField, Tooltip } from "@material-ui/core";
import React, { Component } from "react";
import FormInputCurrency from "../../../components/FormItem/FormInputCurrency";
import DeleteIcon from "@material-ui/icons/Delete";
import "./style.scss";
import moment from "moment";
function HistoryPriceView(props) {
  const { item , onChangeRemoveRowHistoryPrice, onChangeRowHistoryPrice} = props;
  return (
    <div className="container-history-price" key={item.id}>
      <div className="form-group row">
        <div className="col-12 col-md-1 col-lg-1 d-flex align-items-center justify-content-center">
          <Tooltip title="Xóa">
            <IconButton aria-label="delete" onClick={()=>onChangeRemoveRowHistoryPrice(item.id)}>
              <DeleteIcon className="text-danger" />
            </IconButton>
          </Tooltip>
        </div>

        <div className="col-12 col-md-3 col-lg-3">
          <label className="text-dark">Ngày áp dụng</label>
          <TextField
            type="date"
            color="primary"
            value={item.dateApply ? moment(new Date(item.dateApply)).format('YYYY-MM-DD'): ''}
            onChange={e=>onChangeRowHistoryPrice(item.id, 'dateApply', e.target.value)}
            className="form-control p-0 mt-0"
          />
        </div>
        <div className="col-12 col-md-2 col-lg-2">
          <label className="text-dark">Event</label>
          <TextField
            name="event"
            fullWidth
            value={item.event}
            type="text"
            className="form-control"
            onChange={e=>onChangeRowHistoryPrice(item.id, 'event', e.target.value)}
          />
        </div>
        <div className="col-12 col-md-2 col-lg-2">
          <label className="text-dark">Giá</label>
          <TextField
            name="price"
            fullWidth
            type="number"
            value={item.price}
            className="form-control"
            onChange={e=>onChangeRowHistoryPrice(item.id, 'price', e.target.value)}
          />
        </div>
        <div className="col-12 col-md-2 col-lg-2">
          <label className="text-dark">% thay đổi </label>
          <TextField
            name="changeRate "
            fullWidth
            type="number"
            value={item.changeRate}
            className="form-control"
            onChange={e=>onChangeRowHistoryPrice(item.id, 'changeRate', e.target.value)}
          />
        </div>
        <div className="col-12 col-md-2 col-lg-2">
          <label className="text-dark">Giá m2 </label>
          <TextField
            name="costPerM2"
            fullWidth
            type="number"
            value={item.costPerM2}
            className="form-control"
            onChange={e=>onChangeRowHistoryPrice(item.id, 'costPerM2', e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

export default HistoryPriceView;
