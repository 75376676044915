//export const GOOGLE_API_KEY = "AIzaSyAtVwySsRbLZGqDVzfHm1LD9LkoCnO8G0Q";
export const GOOGLE_API_KEY = "AIzaSyBpL4ImLERcE1UQswgIBTdYueGkO2Zm4mE";

export const GOOGLE_MAPS_DEFAULT_CENTER_LATLNG = {
    lat: 21.003001,
    lng: 105.820104
};

export const GOOGLE_MAPS_DEFAULT_LIBRARIES = [
    "places"
];

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALISE = 'ACCOUNT_INITIALISE';
export const REFRESH_TOKEN_INTERVAL = 800;
export const TIME_TO_IDLE = 1000;